.wrapper {
  .header {
    margin: 20px;
    display: flex;
    justify-content: center;

    .tabs {
      min-width: 200px;
      display: flex;
      justify-content: space-around;

      .tab {
        min-width: 100px;
        border: 1px solid #000;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
      }
    }

    .blankDiv {
      width: 60px;
    }
  }

  .content {
    min-height: 84dvh;
    background-color: #fff;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;

    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .headerLabel {
      font-family: "Montserrat-SemiBold";
      font-size: 14px;

      .tastingLabel {
        font-family: "Montserrat-Regular";
      }
    }

    svg {
      width: 40px;
    }
  }
}
