@import "/src/styles/colors";

.addressDetailsWrapper {
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .fields {
    margin-top: 20px;
    .header {
      width: 100%;
      // border-bottom: 1px solid #20202035;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        &:last-child {
          display: flex;
          align-items: center;
        }
      }

      .firstIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: var(--color);
        margin-right: 10px;

        svg {
          margin-right: 5px;
        }
      }

      .icon {
        cursor: pointer;
      }
    }

    h2 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-family: "Montserrat-SemiBold";
      font-size: 18px;
    }

    input {
      width: unset;
    }

    .addressOptions {
      display: flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;

      .radio {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid var(--color);
        margin-right: 10px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  @media screen and (max-width: 1180px) {
    max-width: 90% !important;
    margin: 0 auto;

    .fields {
      .header {
        justify-content: end;
        align-items: normal;
        flex-direction: column-reverse;
        margin-top: 10px;

        div {
          &:last-child {
            justify-content: flex-start;
            margin-top: 5px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .buttonContainer {
      margin-top: 10%;
    }
  }
}
