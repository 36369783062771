.multiSelectDropdownContainer {
  position: relative;
  width: 120px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 5px;
  border: 1px solid #000;
  cursor: pointer;
  transition: height 0.3s ease-in-out;

  .dropdownLabel {
    width: 100%;
    padding: 5px;
    cursor: pointer;
    color: #000;
    text-align: center;
    font-family: "Montserrat-SemiBold";
    font-size: 12px;
    font-style: normal;
    line-height: 20px;
    text-transform: capitalize;
    display: flex;
    justify-content: space-between;
    align-items: center;

    svg {
      position: absolute;
      right: 5px;
    }
  }

  .dropdownContent {
    position: absolute;
    top: 100%;
    left: 0;
    width: 190px !important;
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    margin-top: 10px;
    max-height: 250px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 2px 24px 0px rgba(0, 0, 0, 0.1);
    z-index: 9999;

    .filters {
      max-height: 200px;
      overflow: auto;
      display: flex;
      flex-direction: column;
      margin-right: 0px !important;

      label {
        display: flex;
        padding: 5px 5px 5px 0px;
        color: #000;
        font-size: 14px;
        input {
          accent-color: var(--color);
          margin-right: 5px;
        }
      }
    }
    .buttonsContainer {
      margin-top: 10px;
      margin-right: 0px !important;
      display: flex;
      justify-content: space-between;

      .applyButton,
      .cancelButton {
        padding: 5px 10px;
        cursor: pointer;
        text-transform: uppercase;
      }

      .applyButton {
        background-color: transparent;
        color: var(--color);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: normal;
        letter-spacing: -0.154px;
        border: none;
      }

      .cancelButton {
        background-color: transparent;
        color: var(--color);
        text-align: right;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-size: 12px;
        line-height: normal;
        letter-spacing: -0.154px;
        border: none;
      }
    }

    .closeFilterBtn {
      background-color: #000;
      margin: 10px auto;
      border-radius: 5px;
      color: #fff;
      padding: 5px 5px;
      text-align: center;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    height: 40px;
    background-color: #f8f9fb;
    margin: 10px 4px 10px 0px;
    border-radius: 7px;
    border: 0px;

    .dropdownLabel {
      color: #000;
      width: 100% !important;
    }

    .dropdownContent {
      width: 96% !important;

      .filters {
        width: 100% !important;
      }

      .buttonsContainer {
        margin-top: 0px;
      }
    }
  }
}
