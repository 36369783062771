@import "/src/styles/colors";

.backgroundAtmospherePageWrapper {
  padding: 0px 20px 20px 20px;
  overflow-x: hidden;

  .tabsContent {
    .tabs {
      margin: 20px 5px;
      display: flex;
      align-items: center;

      .item {
        width: 120px;
        padding: 10px;
        font-size: 15px;
        font-family: "Montserrat-Regular";
        color: $main-color;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        text-align: center;
        cursor: pointer;
      }
    }

    .itemContainer {
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .hint {
        font-family: "Montserrat-Regular";
        font-size: 20px;
        max-width: 300px;
        text-align: center;
        margin: 30px 0px;
      }
      .button {
        margin-top: 30px;
        margin-left: 50px;
      }
    }

    .scrollable {
      max-width: 40dvw;
      overflow: scroll;
      display: flex;
      justify-content: space-between;
      margin-left: 50px;
      flex-grow: 1;

      .image {
        border: 1px solid #000;
        margin-right: 20px;
        position: relative;
        cursor: pointer;
        border-radius: 5px;

        img {
          width: 200px;
          height: 300px;
          object-fit: cover;
          border-radius: 5px;
        }

        .selected {
          position: absolute;
          bottom: 20px;
          left: calc(50% - 10px);
        }
      }
    }

    .colours {
      max-width: 250px;
      display: flex;
      justify-content: space-between;
      position: relative;

      .selectedColour {
        margin: 0 auto;
        .selectedIcon {
          margin: 15px 0px 0px 15px;
        }
      }
      div {
        width: 56px;
        height: 56px;
        border-radius: 50px;
        cursor: pointer;
      }
    }

    .colourBtnApply {
      margin-top: 15%;
      margin-left: 11%;
    }
  }

  @media screen and (max-width: 1200px) {
    .scrollable {
      max-width: 30dvw !important;
    }
  }

  @media screen and (max-width: 1180px) {
    h1 {
      text-align: center;
    }
    .tabsContent .tabs {
      width: 100% !important;
      justify-content: center !important;
    }

    .colours {
      width: 100%;
      margin: 0 auto;
    }

    .itemContainer {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      margin: 0 auto;
      text-align: center;
      align-items: center;
      justify-content: center;

      .selectedIcon {
        margin: 15px 0px 0px 0px !important;
      }

      .button {
        margin-left: 0px !important;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .colourBtnApply {
      display: flex;
      justify-content: center;
      margin-left: 0px !important;
    }
    .scrollable {
      max-width: 63dvw !important;
      margin-left: 20px !important;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 0px !important;

    .scrollable {
      max-width: 90dvw !important;
      margin-left: 20px !important;
    }
  }
}
