.balanceWrapper {
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
    color: var(--color);
    font-family: "Montserrat-Bold";
    font-size: 18px;
    line-height: 18px;
  }

  .valuesContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--color);
    width: 100%;

    .row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;

      .label {
        width: 60%;
      }

      .price {
        width: 40%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  .bottomPart {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    padding-top: 10px;

    span {
      color: var(--color);
      font-family: "Montserrat-Bold";
      font-size: 18px;
      line-height: 18px;
    }

    .label {
      width: 60%;
    }

    .price {
      width: 40%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  @media screen and (max-width: 1180px) {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  }

  @media screen and (max-width: 768px) {
    .valuesContent {
      .row {
        .label {
          width: 57%;
          margin-right: 10px;
        }
        .price {
          width: 43%;
        }
      }
    }
    .bottomPart {
      .label {
        width: 57%;
        margin-right: 10px;
      }
      .price {
        width: 43%;
      }
    }
  }
}
