.gridContainer {
  display: grid;
  grid-gap: 0px 10px;
  grid-template-columns: repeat(4, 401px);

  > div {
    box-sizing: border-box;
  }

  // @for $i from 1 through 1000 {
  //   > div:nth-of-type(#{$i}) {
  //     grid-area: i#{$i};
  //   }
  // }

  @media screen and (min-width: 1880px) {
    grid-template-columns: repeat(4, 401px) !important;
  }

  // 5 columns grid
  @media screen and (min-width: 2250px) {
    grid-template-columns: repeat(5, 401px) !important;

    & {
      grid-template-areas:
        "i1 i2 i3 i4 i5"
        "i6 i7 i8 i9 i10"
        "i11 i12 i13 i14 i15"
        "i16 i17 i18 i19 i21"
        "i20 i20 i22 i23 i24"
        "i20 i20 i25 i26 i27"
        "i28 i29 i30 i31 i32"
        "i33 i34 i35 i36 i37"
        "i38 i39 i40 i40 i41"
        "i42 i43 i40 i40 i44"
        "i45 i46 i47 i48 i49"
        "i50 i51 i52 i53 i54"
        "i55 i56 i57 i58 i59"
        "i61 i62 i63 i60 i60"
        "i64 i65 i66 i60 i60"
        "i67 i68 i69 i70 i71"
        "i72 i73 i74 i75 i76"
        "i77 i78 i79 i81 i82"
        "i83 i80 i80 i84 i85"
        "i86 i80 i80 i87 i88"
        "i89 i90 i91 i92 i93"
        "i94 i95 i96 i97 i98"
        "i99 i101 i102 i100 i100"
        "i103 i104 i105 i100 i100"
        "i106 i107 i108 i109 i110"
        "i111 i112 i113 i114 i115"
        "i116 i120 i120 i117 i118"
        "i119 i120 i120 i121 i122"
        "i123 i124 i125 i126 i127"
        "i128 i129 i130 i131 i132"
        "i133 i134 i135 i136 i137"
        "i138 i139 i140 i140 i141"
        "i142 i143 i140 i140 i144"
        "i145 i146 i147 i148 i149"
        "i150 i151 i152 i153 i154"
        "i155 i156 i157 i158 i159"
        "i160 i160 i161 i162 i163"
        "i160 i160 i164 i165 i166"
        "i167 i168 i169 i170 i171"
        "i172 i173 i174 i175 i176"
        "i177 i178 i180 i180 i179"
        "i181 i182 i180 i180 i183"
        "i184 i185 i186 i187 i188"
        "i189 i190 i191 i192 i193"
        "i194 i195 i196 i197 i198"
        "i199 i201 i202 i200 i200"
        "i203 i204 i205 i200 i200"
        "i206 i207 i208 i209 i210"
        "i211 i212 i213 i214 i215"
        "i216 i217 i218 i219 i221"
        "i220 i220 i222 i223 i224"
        "i220 i220 i225 i226 i227"
        "i228 i229 i230 i231 i232"
        "i233 i234 i235 i236 i237"
        "i238 i239 i240 i240 i241"
        "i242 i243 i240 i240 i244"
        "i245 i246 i247 i248 i249"
        "i250 i251 i252 i253 i254"
        "i255 i256 i257 i260 i260"
        "i258 i259 i261 i260 i260"
        "i262 i263 i264 i265 i266"
        "i267 i268 i269 i270 i271"
        "i272 i273 i274 i275 i276"
        "i277 i278 i279 i281 i282"
        "i280 i280 i283 i284 i285"
        "i280 i280 i286 i287 i288"
        "i289 i290 i291 i292 i293"
        "i294 i295 i296 i297 i298"
        "i299 i301 i300 i300 i302"
        "i303 i304 i300 i300 i305"
        "i306 i307 i308 i309 i310"
        "i311 i312 i313 i314 i315"
        "i316 i317 i318 i319 i321"
        "i320 i320 i322 i323 i324"
        "i320 i320 i325 i326 i327"
        "i328 i329 i330 i331 i332"
        "i333 i334 i335 i336 i337"
        "i338 i339 i340 i340 i341"
        "i342 i343 i340 i340 i344"
        "i345 i346 i347 i348 i349"
        "i350 i351 i352 i353 i354"
        "i355 i356 i357 i358 i359"
        "i361 i360 i360 i362 i363"
        "i364 i360 i360 i365 i366"
        "i367 i368 i369 i370 i371"
        "i372 i373 i374 i375 i376"
        "i377 i378 i379 i380 i380"
        "i381 i382 i383 i380 i380"
        "i384 i385 i386 i387 i388"
        "i389 i390 i391 i392 i393"
        "i394 i395 i396 i397 i398"
        "i399 i400 i400 i401 i402"
        "i403 i400 i400 i404 i405"
        "i406 i407 i408 i409 i410"
        "i411 i412 i413 i414 i415"
        "i416 i417 i418 i419 i421"
        "i420 i420 i422 i423 i424"
        "i420 i420 i425 i426 i427"
        "i428 i429 i430 i431 i432"
        "i433 i434 i435 i436 i437"
        "i438 i439 i440 i440 i441"
        "i442 i443 i440 i440 i444"
        "i445 i446 i447 i448 i449"
        "i450 i451 i452 i453 i454"
        "i455 i456 i457 i458 i459"
        "i461 i462 i463 i460 i460"
        "i464 i465 i466 i460 i460"
        "i467 i468 i469 i470 i471"
        "i472 i473 i474 i475 i476"
        "i480 i480 i477 i478 i479"
        "i480 i480 i481 i482 i483"
        "i484 i485 i486 i487 i488"
        "i489 i490 i491 i492 i493"
        "i494 i495 i496 i497 i498"
        "i499 i501 i500 i500 i502"
        "i503 i504 i500 i500 i505"
        "i506 i507 i508 i509 i510"
        "i511 i512 i513 i514 i515"
        "i516 i517 i518 i520 i520"
        "i519 i521 i522 i520 i520"
        "i523 i524 i525 i526 i527"
        "i528 i529 i530 i531 i532"
        "i533 i534 i535 i536 i537"
        "i538 i539 i540 i540 i541"
        "i542 i543 i540 i540 i544"
        "i545 i546 i547 i548 i549"
        "i550 i551 i552 i553 i554"
        "i555 i556 i557 i558 i559"
        "i560 i560 i561 i562 i563"
        "i560 i560 i564 i565 i566"
        "i567 i568 i569 i570 i571"
        "i572 i573 i574 i575 i576"
        "i577 i578 i580 i580 i579"
        "i581 i582 i580 i580 i583"
        "i584 i585 i586 i587 i588"
        "i589 i590 i591 i592 i593"
        "i600 i600 i594 i595 i596"
        "i600 i600 i597 i598 i599"
        "i601 i602 i603 i604 i605"
        "i606 i607 i608 i609 i610"
        "i611 i612 i613 i614 i615"
        "i616 i617 i620 i620 i618"
        "i619 i621 i620 i620 i622"
        "i623 i624 i625 i626 i627"
        "i628 i629 i630 i631 i632"
        "i633 i634 i635 i636 i637"
        "i640 i640 i638 i639 i641"
        "i640 i640 i642 i643 i644"
        "i645 i646 i647 i648 i649"
        "i650 i651 i652 i653 i654"
        "i655 i656 i657 i658 i659"
        "i661 i662 i660 i660 i663"
        "i664 i665 i660 i660 i666"
        "i667 i668 i669 i670 i671"
        "i672 i673 i674 i675 i676"
        "i677 i678 i679 i680 i680"
        "i681 i682 i683 i680 i680"
        "i684 i685 i686 i687 i688"
        "i689 i690 i691 i692 i693"
        "i694 i695 i696 i697 i698"
        "i700 i700 i699 i701 i702"
        "i700 i700 i703 i704 i705"
        "i706 i707 i708 i709 i710"
        "i711 i712 i713 i714 i715"
        "i716 i717 i720 i720 i718"
        "i719 i721 i720 i720 i722"
        "i723 i724 i725 i726 i727"
        "i728 i729 i730 i731 i732"
        "i733 i734 i735 i736 i737"
        "i738 i739 i741 i740 i740"
        "i742 i743 i744 i740 i740"
        "i745 i746 i747 i748 i749"
        "i750 i751 i752 i753 i754"
        "i755 i756 i757 i758 i759"
        "i760 i760 i761 i762 i763"
        "i760 i760 i764 i765 i766"
        "i767 i768 i769 i770 i771"
        "i772 i773 i774 i775 i776"
        "i777 i778 i780 i780 i779"
        "i781 i782 i780 i780 i783"
        "i784 i785 i786 i787 i788"
        "i789 i790 i791 i792 i793"
        "i794 i795 i796 i797 i798"
        "i800 i800 i799 i801 i802"
        "i800 i800 i803 i804 i805"
        "i806 i807 i808 i809 i810"
        "i811 i812 i813 i814 i815"
        "i816 i817 i818 i820 i820"
        "i819 i821 i822 i820 i820"
        "i823 i824 i825 i826 i827"
        "i828 i829 i830 i831 i832"
        "i833 i834 i835 i836 i837"
        "i840 i840 i838 i839 i841"
        "i840 i840 i842 i843 i844"
        "i845 i846 i847 i848 i849"
        "i850 i851 i852 i853 i854"
        "i855 i856 i857 i858 i859"
        "i861 i862 i860 i860 i863"
        "i864 i865 i860 i860 i866"
        "i867 i868 i869 i870 i871"
        "i872 i873 i874 i875 i876"
        "i880 i880 i877 i878 i879"
        "i880 i880 i881 i882 i883"
        "i884 i885 i886 i887 i888"
        "i889 i890 i891 i892 i893"
        "i894 i895 i896 i897 i898"
        "i899 i901 i902 i900 i900"
        "i903 i904 i905 i900 i900"
        "i906 i907 i908 i909 i910"
        "i911 i912 i913 i914 i915"
        "i916 i917 . . ." !important;
    }
  }

  // 4 columns grid
  @media screen and (min-width: 1800px) {
    grid-template-columns: repeat(4, 390px);

    & {
      grid-template-areas:
        "i1 i2 i3 i4"
        "i5 i6 i7 i8"
        "i9 i10 i11 i12"
        "i13 i14 i15 i16"
        "i17 i18 i20 i20"
        "i19 i21 i20 i20"
        "i22 i23 i24 i25"
        "i26 i27 i28 i29"
        "i30 i31 i32 i33"
        "i34 i35 i36 i37"
        "i40 i40 i38 i39"
        "i40 i40 i41 i42"
        "i43 i44 i45 i46"
        "i47 i48 i49 i50"
        "i51 i52 i53 i54"
        "i55 i56 i57 i58"
        "i59 i61 i60 i60"
        "i62 i63 i60 i60"
        "i64 i65 i66 i67"
        "i68 i69 i70 i71"
        "i72 i73 i74 i75"
        "i76 i77 i78 i79"
        "i80 i80 i81 i82"
        "i80 i80 i83 i84"
        "i85 i86 i87 i88"
        "i89 i90 i91 i92"
        "i93 i94 i95 i96"
        "i97 i98 i100 i100"
        "i99 i101 i100 i100"
        "i102 i103 i104 i105"
        "i106 i107 i108 i109"
        "i110 i111 i112 i113"
        "i114 i115 i116 i117"
        "i120 i120 i118 i119"
        "i120 i120 i121 i122"
        "i123 i124 i125 i126"
        "i127 i128 i129 i130"
        "i131 i132 i133 i134"
        "i135 i136 i137 i138"
        "i140 i140 i139 i141"
        "i140 i140 i142 i143"
        "i144 i145 i146 i147"
        "i148 i149 i150 i151"
        "i152 i153 i154 i155"
        "i156 i157 i158 i159"
        "i161 i162 i160 i160"
        "i163 i164 i160 i160"
        "i165 i166 i160 i160"
        "i167 i168 i169 i170"
        "i171 i172 i173 i174"
        "i175 i176 i177 i178"
        "i180 i180 i179 i181"
        "i180 i180 i182 i183"
        "i184 i185 i186 i187"
        "i188 i189 i190 i191"
        "i192 i193 i194 i195"
        "i196 i197 i198 i199"
        "i201 i202 i200 i200"
        "i203 i204 i200 i200"
        "i205 i206 i207 i208"
        "i209 i210 i211 i212"
        "i213 i214 i215 i216"
        "i217 i218 i219 i221"
        "i220 i220 i222 i223"
        "i220 i220 i224 i225"
        "i226 i227 i228 i229"
        "i230 i231 i232 i233"
        "i234 i235 i236 i237"
        "i238 i239 i240 i240"
        "i241 i242 i240 i240"
        "i243 i244 i245 i246"
        "i247 i248 i249 i250"
        "i251 i252 i253 i254"
        "i255 i256 i257 i258"
        "i260 i260 i259 i261"
        "i260 i260 i262 i263"
        "i264 i265 i266 i267"
        "i268 i269 i270 i271"
        "i272 i273 i274 i275"
        "i276 i277 i278 i279"
        "i281 i282 i280 i280"
        "i283 i284 i280 i280"
        "i285 i286 i287 i288"
        "i289 i290 i291 i292"
        "i293 i294 i295 i296"
        "i297 i298 i299 i301"
        "i300 i300 i302 i303"
        "i300 i300 i304 i305"
        "i306 i307 i308 i309"
        "i310 i311 i312 i313"
        "i314 i315 i316 i317"
        "i318 i319 i320 i320"
        "i321 i322 i320 i320"
        "i323 i324 i325 i326"
        "i327 i328 i329 i330"
        "i331 i332 i333 i334"
        "i335 i336 i337 i338"
        "i340 i340 i339 i341"
        "i340 i340 i342 i343"
        "i344 i345 i346 i347"
        "i348 i349 i350 i351"
        "i352 i353 i354 i355"
        "i356 i357 i358 i359"
        "i361 i362 i360 i360"
        "i363 i364 i360 i360"
        "i365 i366 i367 i368"
        "i369 i370 i371 i372"
        "i373 i374 i375 i376"
        "i377 i378 i379 i381"
        "i380 i380 i382 i383"
        "i380 i380 i384 i385"
        "i386 i387 i388 i389"
        "i390 i391 i392 i393"
        "i394 i395 i396 i397"
        "i398 i399 i400 i400"
        "i401 i402 i400 i400"
        "i403 i404 i405 i406"
        "i407 i408 i409 i410"
        "i411 i412 i413 i414"
        "i415 i416 i417 i418"
        "i420 i420 i419 i421"
        "i420 i420 i422 i423"
        "i424 i425 i426 i427"
        "i428 i429 i430 i431"
        "i432 i433 i434 i435"
        "i436 i437 i438 i439"
        "i441 i442 i440 i440"
        "i443 i444 i440 i440"
        "i445 i446 i447 i448"
        "i449 i450 i451 i452"
        "i453 i454 i455 i456"
        "i457 i458 i459 i461"
        "i460 i460 i462 i463"
        "i460 i460 i464 i465"
        "i466 i467 i468 i469"
        "i470 i471 i472 i473"
        "i474 i475 i476 i477"
        "i478 i479 i480 i480"
        "i481 i482 i480 i480"
        "i483 i484 i485 i486"
        "i487 i488 i489 i490"
        "i491 i492 i493 i494"
        "i495 i496 i497 i498"
        "i500 i500 i499 i501"
        "i500 i500 i502 i503"
        "i504 i505 i506 i507"
        "i508 i509 i510 i511"
        "i512 i513 i514 i515"
        "i516 i517 i520 i520"
        "i518 i519 i520 i520"
        "i521 i522 i523 i524"
        "i525 i526 i527 i528"
        "i529 i530 i531 i532"
        "i533 i534 i535 i536"
        "i537 i538 i539 i541"
        "i540 i540 i542 i543"
        "i540 i540 i544 i545"
        "i546 i547 i548 i549"
        "i550 i551 i552 i553"
        "i554 i555 i556 i557"
        "i558 i559 i560 i560"
        "i561 i562 i560 i560"
        "i563 i564 i565 i566"
        "i567 i568 i569 i570"
        "i571 i572 i573 i574"
        "i575 i576 i577 i578"
        "i580 i580 i579 i581"
        "i580 i580 i582 i583"
        "i584 i585 i586 i587"
        "i588 i589 i590 i591"
        "i592 i593 i594 i595"
        "i596 i597 i598 i599"
        "i601 i602 i600 i600"
        "i603 i604 i600 i600"
        "i605 i606 i607 i608"
        "i609 i610 i611 i612"
        "i613 i614 i615 i616"
        "i617 i618 i619 i621"
        "i620 i620 i622 i623"
        "i620 i620 i624 i625"
        "i626 i627 i628 i629"
        "i630 i631 i632 i633"
        "i634 i635 i636 i637"
        "i638 i639 i640 i640"
        "i641 i642 i640 i640"
        "i643 i644 i645 i646"
        "i647 i648 i649 i650"
        "i651 i652 i653 i654"
        "i655 i656 i657 i658"
        "i660 i660 i659 i661"
        "i660 i660 i662 i663"
        "i664 i665 i666 i667"
        "i668 i669 i670 i671"
        "i672 i673 i674 i675"
        "i676 i677 i678 i679"
        "i681 i682 i680 i680"
        "i683 i684 i680 i680"
        "i685 i686 i687 i688"
        "i689 i690 i691 i692"
        "i693 i694 i695 i696"
        "i697 i698 i699 i701"
        "i700 i700 i702 i703"
        "i700 i700 i704 i705"
        "i706 i707 i708 i709"
        "i710 i711 i712 i713"
        "i714 i715 i716 i717"
        "i718 i719 i720 i720"
        "i721 i722 i720 i720"
        "i723 i724 i725 i726"
        "i727 i728 i729 i730"
        "i731 i732 i733 i734"
        "i735 i736 i737 i738"
        "i740 i740 i739 i741"
        "i740 i740 i742 i743"
        "i744 i745 i746 i747"
        "i748 i749 i750 i751"
        "i752 i753 i754 i755"
        "i756 i757 i758 i759"
        "i761 i762 i760 i760"
        "i763 i764 i760 i760"
        "i765 i766 i767 i768"
        "i769 i770 i771 i772"
        "i773 i774 i775 i776"
        "i777 i778 i779 i781"
        "i780 i780 i782 i783"
        "i780 i780 i784 i785"
        "i786 i787 i788 i789"
        "i790 i791 i792 i793"
        "i794 i795 i796 i797"
        "i798 i799 i800 i800"
        "i801 i802 i800 i800"
        "i803 i804 i805 i806"
        "i807 i808 i809 i810"
        "i811 i812 i813 i814"
        "i815 i816 i817 i818"
        "i820 i820 i819 i821"
        "i820 i820 i822 i823"
        "i824 i825 i826 i827"
        "i828 i829 i830 i831"
        "i832 i833 i834 i835"
        "i836 i837 i838 i839"
        "i841 i842 i840 i840"
        "i843 i844 i840 i840"
        "i845 i846 i847 i848"
        "i849 i850 i851 i852"
        "i853 i854 i855 i856"
        "i857 i858 i859 i861"
        "i860 i860 i862 i863"
        "i860 i860 i864 i865"
        "i866 i867 i868 i869"
        "i870 i871 i872 i873"
        "i874 i875 i876 i877"
        "i878 i879 i880 i880"
        "i881 i882 i880 i880"
        "i883 i884 i885 i886"
        "i887 i888 i889 i890"
        "i891 i892 i893 i894"
        "i895 i896 i897 i898"
        "i900 i900 i899 i901"
        "i900 i900 i902 i903"
        "i904 i905 i906 i907"
        "i908 i909 i910 i911"
        "i912 i913 i914 i915"
        "i916 i917 . .";
    }
  }

  //  3 columns grid
  @media screen and (max-width: 1800px) {
    grid-template-columns: repeat(3, 401px);

    & {
      grid-auto-rows: auto;
      grid-template-areas:
        "i1 i2 i3"
        "i4 i5 i6"
        "i7 i8 i9"
        "i10 i11 i12"
        "i13 i14 i15"
        "i16 i17 i18"
        "i20 i20 i19"
        "i20 i20 i21"
        "i22 i23 i24"
        "i25 i26 i27"
        "i28 i29 i30"
        "i31 i32 i33"
        "i34 i35 i36"
        "i37 i38 i39"
        "i41 i40 i40"
        "i42 i40 i40"
        "i43 i44 i45"
        "i46 i47 i48"
        "i49 i50 i51"
        "i52 i53 i54"
        "i55 i56 i57"
        "i58 i59 i61"
        "i60 i60 i62"
        "i60 i60 i63"
        "i64 i65 i66"
        "i67 i68 i69"
        "i70 i71 i72"
        "i73 i74 i75"
        "i76 i77 i78"
        "i79 i80 i80"
        "i81 i80 i80"
        "i82 i83 i84"
        "i85 i86 i87"
        "i88 i89 i90"
        "i91 i92 i93"
        "i94 i95 i96"
        "i97 i98 i99"
        "i100 i100 i101"
        "i100 i100 i102"
        "i103 i104 i105"
        "i106 i107 i108"
        "i109 i110 i111"
        "i112 i113 i114"
        "i115 i116 i117"
        "i118 i119 i121"
        "i120 i120 i122"
        "i120 i120 i123"
        "i124 i125 i126"
        "i127 i128 i129"
        "i130 i131 i132"
        "i133 i134 i135"
        "i136 i137 i138"
        "i139 i140 i140"
        "i141 i140 i140"
        "i142 i143 i144"
        "i145 i146 i147"
        "i148 i149 i150"
        "i151 i152 i153"
        "i154 i155 i156"
        "i157 i158 i159"
        "i160 i160 i161"
        "i160 i160 i162"
        "i163 i164 i165"
        "i166 i167 i168"
        "i169 i170 i171"
        "i172 i173 i174"
        "i175 i176 i177"
        "i178 i180 i180"
        "i179 i180 i180"
        "i181 i182 i183"
        "i184 i185 i186"
        "i187 i188 i189"
        "i190 i191 i192"
        "i193 i194 i195"
        "i196 i197 i198"
        "i200 i200 i199"
        "i200 i200 i201"
        "i202 i203 i204"
        "i205 i206 i207"
        "i208 i209 i210"
        "i211 i212 i213"
        "i214 i215 i216"
        "i217 i220 i220"
        "i218 i220 i220"
        "i219 i221 i222"
        "i223 i224 i225"
        "i226 i227 i228"
        "i229 i230 i231"
        "i232 i233 i234"
        "i235 i236 i237"
        "i240 i240 i238"
        "i240 i240 i239"
        "i241 i242 i243"
        "i244 i245 i246"
        "i247 i248 i249"
        "i250 i251 i252"
        "i253 i254 i255"
        "i256 i257 i258"
        "i259 i260 i260"
        "i261 i260 i260"
        "i262 i263 i264"
        "i265 i266 i267"
        "i268 i269 i270"
        "i271 i272 i273"
        "i274 i275 i276"
        "i277 i278 i279"
        "i280 i280 i281"
        "i280 i280 i282"
        "i283 i284 i285"
        "i286 i287 i288"
        "i289 i290 i291"
        "i292 i293 i294"
        "i295 i296 i297"
        "i298 i300 i300"
        "i299 i300 i300"
        "i301 i302 i303"
        "i304 i305 i306"
        "i307 i308 i309"
        "i310 i311 i312"
        "i313 i314 i315"
        "i316 i317 i318"
        "i320 i320 i319"
        "i320 i320 i321"
        "i322 i323 i324"
        "i325 i326 i327"
        "i328 i329 i330"
        "i331 i332 i333"
        "i334 i335 i336"
        "i337 i338 i339"
        "i341 i340 i340"
        "i342 i340 i340"
        "i343 i344 i345"
        "i346 i347 i348"
        "i349 i350 i351"
        "i352 i353 i354"
        "i355 i356 i357"
        "i358 i360 i360"
        "i359 i360 i360"
        "i361 i362 i363"
        "i364 i365 i366"
        "i367 i368 i369"
        "i370 i371 i372"
        "i373 i374 i375"
        "i376 i377 i378"
        "i380 i380 i379"
        "i380 i380 i381"
        "i382 i383 i384"
        "i385 i386 i387"
        "i388 i389 i390"
        "i391 i392 i393"
        "i394 i395 i396"
        "i397 i398 i399"
        "i401 i400 i400"
        "i402 i400 i400"
        "i403 i404 i405"
        "i406 i407 i408"
        "i409 i410 i411"
        "i412 i413 i414"
        "i415 i416 i417"
        "i418 i419 i421"
        "i420 i420 i422"
        "i420 i420 i423"
        "i424 i425 i426"
        "i427 i428 i429"
        "i430 i431 i432"
        "i433 i434 i435"
        "i436 i437 i438"
        "i439 i440 i440"
        "i441 i440 i440"
        "i442 i443 i444"
        "i445 i446 i447"
        "i448 i449 i450"
        "i451 i452 i453"
        "i454 i455 i456"
        "i457 i458 i459"
        "i460 i460 i461"
        "i460 i460 i462"
        "i463 i464 i465"
        "i466 i467 i468"
        "i469 i470 i471"
        "i472 i473 i474"
        "i475 i476 i477"
        "i478 i480 i480"
        "i479 i480 i480"
        "i481 i482 i483"
        "i484 i485 i486"
        "i487 i488 i489"
        "i490 i491 i492"
        "i493 i494 i495"
        "i496 i497 i498"
        "i500 i500 i499"
        "i500 i500 i501"
        "i502 i503 i504"
        "i505 i506 i507"
        "i508 i509 i510"
        "i511 i512 i513"
        "i514 i515 i516"
        "i517 i518 i519"
        "i520 i520 i521"
        "i520 i520 i522"
        "i523 i524 i525"
        "i526 i527 i528"
        "i529 i530 i531"
        "i532 i533 i534"
        "i535 i536 i537"
        "i538 i540 i540"
        "i539 i540 i540"
        "i541 i542 i543"
        "i544 i545 i546"
        "i547 i548 i549"
        "i550 i551 i552"
        "i553 i554 i555"
        "i556 i557 i558"
        "i560 i560 i559"
        "i560 i560 i561"
        "i562 i563 i564"
        "i565 i566 i567"
        "i568 i569 i570"
        "i571 i572 i573"
        "i574 i575 i576"
        "i577 i578 i579"
        "i581 i580 i580"
        "i582 i580 i580"
        "i583 i584 i585"
        "i586 i587 i588"
        "i589 i590 i591"
        "i592 i593 i594"
        "i595 i596 i597"
        "i600 i600 i598"
        "i600 i600 i599"
        "i601 i602 i603"
        "i604 i605 i606"
        "i607 i608 i609"
        "i610 i611 i612"
        "i613 i614 i615"
        "i616 i617 i618"
        "i619 i620 i620"
        "i621 i620 i620"
        "i622 i623 i624"
        "i625 i626 i627"
        "i628 i629 i630"
        "i631 i632 i633"
        "i634 i635 i636"
        "i637 i638 i639"
        "i640 i640 i641"
        "i640 i640 i642"
        "i643 i644 i645"
        "i646 i647 i648"
        "i649 i650 i651"
        "i652 i653 i654"
        "i655 i656 i657"
        "i658 i660 i660"
        "i659 i660 i660"
        "i661 i662 i663"
        "i664 i665 i666"
        "i667 i668 i669"
        "i670 i671 i672"
        "i673 i674 i675"
        "i676 i677 i678"
        "i680 i680 i679"
        "i680 i680 i681"
        "i682 i683 i684"
        "i685 i686 i687"
        "i688 i689 i690"
        "i691 i692 i693"
        "i694 i695 i696"
        "i697 i698 i699"
        "i701 i700 i700"
        "i702 i700 i700"
        "i703 i704 i705"
        "i706 i707 i708"
        "i709 i710 i711"
        "i712 i713 i714"
        "i715 i716 i717"
        "i718 i719 i721"
        "i720 i720 i722"
        "i720 i720 i723"
        "i724 i725 i726"
        "i727 i728 i729"
        "i730 i731 i732"
        "i733 i734 i735"
        "i736 i737 i738"
        "i739 i740 i740"
        "i741 i740 i740"
        "i742 i743 i744"
        "i745 i746 i747"
        "i748 i749 i750"
        "i751 i752 i753"
        "i754 i755 i756"
        "i757 i758 i759"
        "i760 i760 i761"
        "i760 i760 i762"
        "i763 i764 i765"
        "i766 i767 i768"
        "i769 i770 i771"
        "i772 i773 i774"
        "i775 i776 i777"
        "i778 i780 i780"
        "i779 i780 i780"
        "i781 i782 i783"
        "i784 i785 i786"
        "i787 i788 i789"
        "i790 i791 i792"
        "i793 i794 i795"
        "i796 i797 i798"
        "i800 i800 i799"
        "i800 i800 i801"
        "i802 i803 i804"
        "i805 i806 i807"
        "i808 i809 i810"
        "i811 i812 i813"
        "i814 i815 i816"
        "i817 i818 i819"
        "i821 i820 i820"
        "i822 i820 i820"
        "i823 i824 i825"
        "i826 i827 i828"
        "i829 i830 i831"
        "i832 i833 i834"
        "i835 i836 i837"
        "i838 i839 i841"
        "i840 i840 i842"
        "i840 i840 i843"
        "i844 i845 i846"
        "i847 i848 i849"
        "i850 i851 i852"
        "i853 i854 i855"
        "i856 i857 i858"
        "i859 i860 i860"
        "i861 i860 i860"
        "i862 i863 i864"
        "i865 i866 i867"
        "i868 i869 i870"
        "i871 i872 i873"
        "i874 i875 i876"
        "i877 i878 i879"
        "i880 i880 i881"
        "i880 i880 i882"
        "i883 i884 i885"
        "i886 i887 i888"
        "i889 i890 i891"
        "i892 i893 i894"
        "i895 i896 i897"
        "i898 i900 i900"
        "i899 i900 i900"
        "i901 i902 i903"
        "i904 i905 i906"
        "i907 i908 i909"
        "i910 i911 i912"
        "i913 i914 i915"
        "i916 i917 .";
    }
  }

  @media screen and (max-width: 1510px) {
    grid-template-columns: repeat(3, 370px);
  }

  @media screen and (max-width: 1406px) {
    grid-template-columns: repeat(3, 330px);
  }

  @media screen and (max-width: 1300px) {
    grid-template-columns: repeat(3, 300px);
  }

  @media screen and (max-width: 1240px) {
    grid-template-columns: repeat(3, 300px);

    div img:first-child {
      left: 10px !important;
    }
  }
}
