.mainSelect {
  background: transparent;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  height: 40px;
  width: 100%;
  min-width: 60px;
  padding: 0px 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  outline: none;
  font-family: "Montserrat-Medium";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 96% */
  border-radius: 0px !important;
}

.inputLabel {
  color: #000;
  cursor: pointer;
  margin: 15px 0px;

  @media screen and (max-width: 1180px) {
    margin: 20px 0px;
  }
}
