.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;

  svg,
  img {
    margin-bottom: 10px;
  }

  .coinsLabel {
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
  }

  h1 {
    font-family: "Montserrat-Bold";
    font-size: 24px;
    width: 80%;
    margin: 0 auto;
  }

  .sublabels {
    color: #fff;
    width: 80%;
    margin: 20px auto;
    font-size: 14px;

    div {
      margin-bottom: 10px;
    }
  }

  .button {
    width: 233px;
    height: 48px;
    color: #ff6333;
    border: 1px solid #ff6333;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.1s linear;
    font-family: "Montserrat-Medium";
    font-size: 14px;
    margin-top: 30px;

    &:hover {
      background-color: #ff6333;
      color: #fff;
    }
  }
}
