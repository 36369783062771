@import "/src/styles/colors";

.changePasswordContent {
  h1 {
    text-align: center;
    margin-bottom: 50px;
    font-size: 20px;
  }

  .fields {
    h2 {
      border-bottom: 1px solid #20202035;
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-size: 18px;
    }
  }

  .hints {
    max-width: 300px;
    text-align: center;
    margin: 50px auto 50px auto;
    display: flex;
    flex-direction: column;
    color: $main-color;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    line-height: 26px;

    span {
      margin-bottom: 5px;
      &:first-child {
        margin-bottom: 40px;
      }
    }
  }

  .buttonContainer {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .changeAuthSecurityLabel {
      cursor: pointer;
      margin-top: 50px;
      text-align: center;
    }
  }

  .successStep {
    max-width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .icon {
      margin: 100px 0px;
    }
    h2 {
      max-width: 50%;
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 70%;
    margin: 0 auto;

    .successStep {
      max-width: 80%;
      margin-top: 50px;
    }
  }
}
