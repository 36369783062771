@-webkit-keyframes loading-bounce {
  0% {
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  40% {
    transform: translateY(1.275rem) scaleY(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateY(1.275rem) scaleY(0.75);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(1.275rem) scaleY(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes loading-bounce {
  0% {
    transform: translateY(0);
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
    animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
  }
  40% {
    transform: translateY(1.275rem) scaleY(1);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  50% {
    transform: translateY(1.275rem) scaleY(0.75);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  60% {
    transform: translateY(1.275rem) scaleY(1);
    -webkit-animation-timing-function: cubic-bezier(0, 0, 0, 1);
    animation-timing-function: cubic-bezier(0, 0, 0, 1);
  }
  100% {
    transform: translateY(0);
  }
}
.loading {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  // background-color: #fff;
  background-size: contain;
  opacity: 0;
  z-index: 9;
  margin: auto;
}
.bounce {
  animation: loading-bounce 0.7s infinite 0.1s;
  z-index: 9;
  opacity: 1;
  // background-color: #fff;
}
