@import "/src/styles/colors";

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-size: cover;
  background-position: center;
  height: 100dvh;
  overflow: hidden;

  .content {
    overflow: auto;
  }

  @media screen and (max-width: 1180px) {
    .appHeader {
      display: none;
    }
    .content {
      margin-top: 20px;
    }
  }
}
