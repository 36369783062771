@import "/src/styles/colors";

.warningWrapper {
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 30px;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    text-align: justify;
    padding: 0px 20px;
  }

  .buttons {
    margin-top: 50px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
}
