@import "/src/styles/colors";

.personalDetailsWrapper {
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .fields {
    .salutations {
      padding: 20px 5px;
      border-bottom: 1px solid #20202035;
      display: flex;
      align-items: center;

      .salutationItem {
        width: 80px;
        padding: 10px;
        font-size: 15px;
        font-family: "Montserrat-Regular";
        color: #000;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .active {
        background-color: #600f835e;
      }
    }

    h2 {
      border-bottom: 1px solid #20202035;
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-size: 18px;
      font-family: "Montserrat-Semibold";
    }

    input {
      width: 100%;
      font-family: "Montserrat-Regular";
    }

    .withIcons {
      display: flex;
      position: relative;

      div:first-child {
        width: 100%;
      }

      .clickableIcon {
        cursor: pointer;
        svg {
          position: absolute;
          right: 5px;
          top: 10px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 70px;
  }

  @media screen and (max-width: 1180px) {
    max-width: 90% !important;
    margin: 0 auto;
  }
}
