.container {
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1480px) {
    .productsWrapper {
      .product {
        background-size: cover;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .productsWrapper {
      .rightContent {
        img {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .productsWrapper {
      .rightContent {
        margin-left: 10px;
        align-items: center;
        img {
          width: 95%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .productsWrapper {
      .mobileProduct {
        width: 41%;
        min-width: 30dvw;
      }
      .loader {
        width: 41%;
      }
    }
  }
  @media screen and (max-width: 380px) {
    .productsWrapper {
      .mobileProduct {
        width: 40%;
      }

      .loader {
        width: 40%;
      }
    }
  }
}

.containerWithDetails {
  display: grid;
  grid-template-columns: 1fr calc(100% - 420px);

  .productDetailsWrapper {
    width: unset;
  }

  .product {
    width: 401px !important;
  }

  // .specialProduct {
  //   width: 401px !important;
  // }

  @media screen and (min-width: 1600px) {
    // grid-template-columns: 1fr calc(100% - 870px);
    grid-template-columns: 1fr calc(100% - 828px);
  }

  @media screen and (min-width: 1700px) {
    // grid-template-columns: 1fr calc(100% - 870px);
    grid-template-columns: 1fr calc(100% - 830px);
  }

  @media screen and (min-width: 2100px) {
    // grid-template-columns: 1fr calc(100% - 1300px);
    grid-template-columns: 1fr calc(100% - 1240px);
  }

  @media screen and (min-width: 2400px) {
    // grid-template-columns: 1fr calc(100% - 1730px);
    grid-template-columns: 1fr calc(100% - 1650px);
  }

  @media screen and (min-width: 2800px) {
    // grid-template-columns: 1fr calc(100% - 2160px);
    grid-template-columns: 1fr calc(100% - 2070px);
  }
}

.productsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .product {
    width: 401px;
    height: 370px;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  }

  .specialProduct {
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    border: 2px solid var(--color);

    .infoParts {
      height: 85% !important;
      justify-content: space-between !important;

      .productRating {
        margin-bottom: 30px;
      }

      .productActions {
        width: 34% !important;
      }
    }

    .imagePart {
      margin-bottom: 60px;

      img {
        &:first-child {
          height: 530px !important;
          width: 270px !important;
        }

        &:last-child {
          overflow: hidden;
          left: -300px;
          width: 870px;
        }
      }
    }

    .productInfo {
      height: 95% !important;
    }
  }

  .product,
  .specialProduct {
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border-radius: 8px;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 10px;
    color: #000;
    background-size: contain;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    .infoParts {
      width: 40%;
      height: 80%;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .regionLabel {
        cursor: pointer;
        color: #797979;
        font-size: 14px;
        margin-top: 5px;
      }

      .bottoms {
        z-index: 999;

        .bookmarksIcon {
          height: 25px;
          width: 25px;
        }

        .productActions {
          width: 70%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          .addToCartButton {
            width: 48px;
            height: 48px;
            background-color: var(--color);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }
        }
      }
    }

    .imagePart {
      width: 40%;
      height: 100%;
      margin-left: -30px;
      display: flex;
      cursor: pointer;
      align-items: center;

      .img {
        width: 130px;
      }

      &:hover {
        filter: blur(5px);

        & + .productInfo {
          width: 40% !important;
          opacity: 1;
          background-color: #f1f4f5;
          right: 0;
          height: 91%;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border-radius: 5px;
          z-index: 99;
          color: #000;
          align-items: center;
          width: -webkit-fill-available;
          padding: 20px;

          .boldText {
            color: var(--color);
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: "Montserrat-SemiBold";
            font-size: 14px;
            font-style: normal;
            line-height: 19px;
            display: flex;
            align-items: center;

            &:first-child {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .productImage {
      position: relative;
      object-fit: contain;
      width: 140px;
      z-index: 10;
    }

    .productRating {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      svg {
        margin-right: 5px;
      }
    }

    .name {
      color: #000;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-SemiBold";
      font-size: 14px;
      font-style: normal;
      line-height: 14.011px;
      margin-top: 20px;
      cursor: pointer;

      .vintageLabel {
        margin: 10px 0px;
        font-family: "Montserrat-Regular";
        font-size: 14px;
        cursor: pointer;
      }
    }

    .bottleSizeLabel {
      font-family: "Montserrat-Regular";
      font-size: 14px;
      cursor: pointer;
    }

    .price {
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-SemiBold";
      font-size: 18px;
      font-style: normal;
      line-height: 17.237px;
      margin: 10px 0px;
      cursor: pointer;
    }

    .infoText {
      color: #fff;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Medium";
      font-size: 13.874px;
      font-style: normal;
      line-height: 17.237px;
    }

    .imagePart {
      position: relative;

      .bottleShadow,
      .specialBottleShadow {
        position: absolute;
        left: -146px;
        z-index: 8;
      }

      .bottleShadow {
        bottom: 26px;
      }
      .specialBottleShadow {
        bottom: 70px;
      }
    }

    .productInfo {
      position: absolute;
      bottom: 0px;
      right: -140px;
      color: #ffff;
      transition: right 0.2s linear;
      opacity: 0;
      height: 0px;
      font-size: 14px;
      pointer-events: none;

      div:first-child {
        max-width: 200px;
      }

      &:hover {
        width: 40% !important;
        opacity: 1;
        background-color: #f1f4f5;
        right: 0;
        height: 91%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        border-radius: 5px;
        z-index: 99;
        color: #000;
        align-items: center;
        width: -webkit-fill-available;
        padding: 20px;
        pointer-events: all;

        .boldText {
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 19px;
          display: flex;
          align-items: center;

          &:first-child {
            margin-top: 20px;
          }
        }
      }
    }
  }

  .discount {
    font-size: 12px;
    color: #ff6333;
    text-decoration: line-through;
  }

  .productWithDetailsOpen {
    width: 100% !important;
    max-width: 100% !important;
    height: 370px;
  }

  .noProductsLabel {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 100px;
    color: var(--color);
  }

  .mapContainer {
    width: 100%;
    position: relative;
    height: calc(100dvh - 170px);

    .wrapper {
      position: relative;
      width: 85%;
      display: flex;
      justify-content: center;
      margin: 0 auto;
      .mapFilters {
        width: 100%;
        z-index: 9999;
        position: absolute;
        top: 10px;
        left: 0px;
        display: flex;
        justify-content: space-around;
        margin: 0px auto;
        z-index: 9999;

        div {
          min-width: 50px;
          height: 28px;
          border: 1px solid #000;
          border-radius: 8px;
          background-color: #fff;
          padding: 0px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          cursor: pointer;
        }
      }
    }
  }

  .searchBox {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    width: 100% !important;
    margin-left: 10px;
  }
  .searchInput {
    width: 90% !important;
    display: flex;
    align-items: center;
    display: flex;
    align-content: center;
    transition: width 0.3s ease-in-out;
    border: 1px solid #979797;
    border-radius: 7px;

    svg {
      margin: 0px 10px;
    }
    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      color: #000;
      font-family: "Montserrat-SemiBold";
      font-size: 12px;
      font-style: normal;
      text-transform: capitalize;

      &::placeholder {
        color: #000;
      }
    }
  }

  .listTabs {
    width: 100%;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;

    div {
      width: 65px;
      height: 100%;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:first-child {
        margin-right: 5px;
      }
    }
  }

  .mobileProduct {
    width: 45%;
    height: 200px;
    border: 1px solid rgba(151, 151, 151, 0.2078431373);
    margin: 5px auto;
    border-radius: 12px;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    position: relative;

    .leftContent {
      width: 50%;
      height: 73%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .header {
        position: absolute;
        top: 10px;
        left: 10px;

        .region {
          font-size: 9px;
        }

        .productRating {
          color: var(--color);
          display: flex;
          align-items: center;
          font-size: 10px;
          svg {
            margin-right: 5px;
            width: 16px;
          }
        }
      }

      .name {
        color: #2f3447;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
        font-family: "Montserrat-SemiBold";
      }

      .category {
        color: #2f3447;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
      }
      .price {
        color: var(--color);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 16px;
        margin-bottom: 10px;
      }

      .bottleInfo {
        margin-bottom: 10px;
        color: var(--color);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-size: 10px;
        font-style: normal;
        line-height: 16px;
      }
    }

    .rightContent {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;

      img {
        height: 150px;
        object-fit: contain;
      }

      .specialProductImage {
        height: 280px;
      }
    }
  }

  .loader {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 15px;
  }

  .mobileProduct:nth-of-type(4n + 3),
  .mobileProduct:nth-of-type(4n + 3) + * {
    background-color: #97979735;
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .loader {
      width: 45%;
    }

    .mapContainer {
      height: 90dvh;
    }
  }
}

.pWrapperWithDetails {
  display: flex;
  flex-wrap: wrap;
  margin-right: 5px;
  align-self: flex-start;
}

.productDetailsWrapper {
  background-color: #fff;
  border-radius: 5px;
  width: 50%;
  margin: 5px 10px 5px 0px;
  padding: 0px 10px;
  height: fit-content;
  position: sticky;
  top: 0px;
  overflow: auto;
  max-height: calc(100dvh - 175px);
  box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
}

@media screen and (max-width: 1510px) {
  .productsWrapper {
    .product {
      width: 370px;
    }
  }

  .specialProduct {
    width: 754px;
  }
}

@media screen and (max-width: 1406px) {
  .productsWrapper {
    .product {
      width: 330px;
    }
  }
  .specialProduct {
    width: 670px;
  }
}

@media screen and (max-width: 1300px) {
  .productsWrapper {
    .product {
      width: 300px;
    }
  }

  .specialProduct {
    width: 610px;
  }
}

@media screen and (max-width: 1240px) {
  .product {
    width: 290px;
  }

  // .specialProduct {
  //   width: 580px;
  // }
}
