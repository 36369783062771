.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-top: 60px;
  min-height: 40%;
  padding-bottom: 40px;

  svg {
    margin-bottom: 20px;
  }

  .imageBox {
    width: 320px;
    height: 320px;
    border: 1px solid #000;
    border-radius: 12px;
    margin: 50px 0px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
      color: white;
      padding: 30px;
      text-align: center;
      font-size: 20px;
      font-family: "Montserrat-Bold";
      border-radius: 10px;
      border-bottom: 1px solid #000;
    }
  }

  .hint {
    max-width: 550px;
    text-align: center;
    font-family: "Montserrat-Medium";
    font-size: 14px;
    color: #ff6333;
    margin: 40px 0px;
  }

  @media screen and (max-width: 1180px) {
    .hint {
      max-width: 300px;
    }
  }
}
