@import "/src/styles/colors";

.accountSetupWrapper {
  max-width: 320px;
  margin: 0 auto;
  text-align: center;

  h1 {
    text-align: center;
    margin-bottom: 50px;
    word-wrap: break-word;
  }

  .hint {
    font-size: 20px;
    text-align: center;
  }
  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      color: #000;
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .card {
    display: flex;
    max-width: 320px;
    height: 210px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px 0px $main-color;
    transform: rotate(-9deg);
    margin-top: 10%;
    color: #fff;

    .cardImage {
      z-index: -1;
      position: absolute;
      width: 100%;
    }

    .iconContainer {
      overflow: hidden;
      margin-top: -20px;
    }

    .logo {
      margin: 20px 0px 0px 20px;

      img {
        width: 90px;
        height: 30px;
        object-fit: contain;
      }
    }
    .info {
      display: flex;
      flex-direction: column;
      text-align: left;
      position: absolute;
      left: 30px;
      bottom: 5px;

      span {
        &:first-child {
          font-size: 14px;
          opacity: 0.8;
        }
      }

      .membershipId {
        font-family: "Montserrat-Bold";
        font-size: 14px;
        margin: 5px 0px;
      }
      .username {
        color: #fff;
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px; /* 300% */
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 30%;
  }

  @media screen and (max-width: 1180px) {
    margin-top: 20px;
    .card {
      div:first-child {
        width: 40%;
      }
    }
    .header {
      height: 40px;
    }
  }
  @media screen and (max-width: 500px) {
    max-width: 320px !important;
  }
}
