.wrapper {
  .rateContent {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 400px;

    .title {
      font-family: "Montserrat-SemiBold";
      font-size: 24px;
    }
    .options {
      display: flex;
      justify-content: space-between;

      .option {
        cursor: pointer;
        position: relative;
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid transparent;

        .icon {
          position: absolute;
          top: -50px;
          left: 30px;
          transition: all 0.5s ease-in-out;
        }
      }

      .activeOption {
        width: 75px;
        height: 75px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid #f76234;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .rateContent {
      .option {
        .icon {
          left: 20px !important;
        }
      }
      .option,
      .activeOption {
        width: 65px !important;
        height: 65px !important;
      }
    }
  }
  @media screen and (max-width: 400px) {
    .rateContent {
      height: 340px;
      .option {
        .icon {
          left: 7px !important;
        }
      }
      .option,
      .activeOption {
        width: 55px !important;
        height: 55px !important;
      }
    }
  }
}
