.transactionDetailsWrapper {
  .boxHeader {
    max-width: 100%;
    background-color: #fff;

    .hint {
      color: #000;
      font-size: 14px;
      margin-bottom: 20px;
    }

    .title {
      color: var(--color);
      font-family: "Montserrat-Bold";
      font-size: 16px;
      font-weight: 700;
      line-height: 22px;
    }

    .price {
      color: #000;
      font-family: "Montserrat-Bold";
      font-size: 40px;
      font-style: normal;
      margin-top: 20px;
    }
  }

  .mainContent {
    padding: 20px;

    @media screen and (max-width: 1180px) {
      padding: 10px;
    }
    h2 {
      color: var(--color);
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Bold";
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 22px;
      text-transform: uppercase;
    }

    .infoWithLabel {
      display: flex;
      flex-direction: column;
      margin-top: 20px;

      .coloredLabel {
        color: var(--color);
        margin-bottom: 10px;
        font-family: "Montserrat-Medium";
        font-weight: 500;
      }
      div {
        display: flex;
        flex-direction: column;

        span {
          margin-bottom: 10px;
        }
      }
    }

    .bottomPart {
      margin-top: 20px;
      color: var(--color);
      font-family: "Montserrat-Medium";

      span {
        &:last-child {
          color: #000;
          font-family: "Montserrat-Regular";
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 50px;

      .downloadInvoiceBtn {
        cursor: pointer;
        height: 48px;
        background-color: var(--color);
        color: #fff;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }
  }
}
