.container {
  max-width: 500px;
  background-color: #fff;
  border-radius: 4px;
  margin: 0 auto;
  min-height: 90%;
  padding: 20px;

  .title {
    text-align: center;

    span {
      color: #000;
      font-family: "Montserrat-Regular";
      font-size: 16px;
    }
  }

  .coloredLabel {
    color: var(--color);
    text-align: center;
    text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat-Medium";
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    margin: 50px 0px 100px 0px;
  }

  .closeButton {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content {
    .userInfoRequiredContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 100%;

      span {
        font-size: 16px;
        font-family: "Montserrat-Regular";
        width: 80%;
      }

      .inputs {
        width: 90%;
        margin-top: 100px;

        div {
          width: 100%;
        }
        input {
          background-color: #fff;
          height: 64px;
          border: none;
          border-radius: 4px;
          width: 100%;
          padding: 20px 15px 5px 15px;
          font-size: 16px;
          outline: none;
          margin: 5px 0px;
          font-family: "Montserrat-Medium";
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);

          &::placeholder {
            color: #000;
          }
        }
      }

      .button {
        margin-top: 50px;
      }
    }
  }

  .wrapper {
    max-width: 380px;
    margin: 0 auto;

    .tabs {
      max-width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 30px auto 0 auto;
      text-align: center;
      font-size: 14px;
      line-height: 20px;
      background-color: transparent;
      border-radius: 4px;

      div {
        width: 200px;
        padding: 10px;
        cursor: pointer;
        color: #000;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
      }

      .activeTab {
        color: #fff;
        background-color: var(--color);
      }
    }

    .amount {
      margin-top: 20px;

      span:first-child {
        margin-left: 10px;
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 16px;
        line-height: 20px;
        text-align: left;
      }
      .priceInput {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-top: 20px;

        .currencySelect {
          display: flex;
          align-items: center;
          justify-content: center;
          select {
            width: 60px;
            height: 40px;
            background-color: transparent;
            color: var(--color);
            border-radius: 4px;
            text-align: center;
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 1px;
            text-overflow: "";
            outline: none;
            margin-right: 10px;
            font-size: 16px;
            text-align-last: center;
          }
        }

        .amountValue {
          max-width: 300px;
        }
      }
    }

    .amountItems {
      padding: 20px 5px;
      display: flex;
      align-items: center;
      text-align: center;

      .item {
        min-width: 70px;
        padding: 8px;
        font-size: 15px;
        font-family: "Montserrat-Regular";
        color: #000;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        cursor: pointer;
      }
      .active {
        background-color: #600f835e;
      }
    }

    .paymentMethod {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .items {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;

        .leftPart {
          display: flex;
          align-items: center;

          .pMethodTitle {
            color: #000;
            font-family: "Montserrat-Regular";
            font-size: 18px;
            line-height: normal;
          }
          .pMethodLabel {
            font-family: "Montserrat-Regular";
            font-size: 16px;
            line-height: normal;
            color: var(--color);
          }
        }
      }
      .divWithBorder {
        background-color: var(--color);
        width: 3px;
        min-height: 40px;
      }
    }

    .button {
      margin-top: 50px;
      display: flex;
      justify-content: center;
    }

    .title,
    label {
      color: var(--color);
      font-family: "Montserrat-Bold";
      font-size: 16px;
      line-height: 20px;
      text-align: left;
    }
  }
  .choosePMContent {
    display: flex;
    flex-direction: column;
    margin: 0px 50px;

    h3 {
      color: #000;
      font-family: "Montserrat-Bold";
      font-size: 18px;
      line-height: normal;
      margin-top: 50px;
    }

    .items {
      .row {
        max-width: 380px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        margin-top: 20px;
        border-bottom: 1px solid var(--color);
        cursor: pointer;

        .title {
          color: #000;
          font-family: "Montserrat-Bold";
          font-size: 18px;
          line-height: normal;
        }
        .label {
          color: var(--color);
          font-family: "Montserrat-Regular";
          font-size: 16px;
          line-height: normal;
          margin-top: 5px;
        }
        .icon {
          margin-right: 20px;
          padding-top: 10px;
        }

        div:first-child {
          display: flex;
        }
      }
    }
  }

  .mappedDays {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .oneRow {
      display: flex;
      justify-content: space-between;
      width: 80%;
      border-bottom: 2px solid var(--color);
      padding: 20px 0px;
      cursor: pointer;

      .order {
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 16px;
      }

      .value {
        font-family: "Montserrat-SemiBold";
        font-size: 18px;
      }
    }
  }

  .successWrapper {
    h1 {
      text-align: center;
      margin-bottom: 50px;
    }

    h2 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      text-align: center;
      font-size: 22px;
      font-family: "Montserrat-Regular";
    }

    .successStep {
      max-width: 570px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      .icon {
        margin: 100px 0px;
      }

      .monthlySubscriptionBtn {
        color: var(--color);
        height: 40px;
        border-radius: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 255px;
        margin-bottom: 10%;
        cursor: pointer;
        font-size: 14px;
        font-family: "Montserrat-Bold";
        width: 80%;
        text-align: center;
      }
      h2 {
        margin: 0 auto;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    background-color: transparent;
    max-width: 100%;

    .wrapper {
      max-width: 100%;

      .tabs {
        max-width: 95%;

        div {
          width: 160px;
        }
      }

      .amount {
        .priceInput {
          justify-content: flex-start;
          div:first-child {
            margin-left: 5px;
          }
          div:last-child {
            width: 80%;

            div {
              width: 90%;
            }
          }
        }
        .amountValue {
          max-width: unset !important;
        }
      }
    }
    .choosePMContent {
      margin: 0;
      .items {
        .row {
          max-width: 100%;
        }
      }
    }

    .monthlySubscriptionBtn {
      width: 90%;
      padding: 10px;
    }

    .failedPayment {
      height: 400px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
  }

  @media screen and (max-width: 400px) {
    .wrapper {
      .tabs {
        max-width: 100%;

        div {
          padding: 10px 8px;
        }
      }
    }
  }
}

.canceledPaymentError {
  display: flex;
  word-break: keep-all;

  div {
    &:first-child {
      max-width: 300px;
    }

    span {
      max-width: 220px;
      color: #ff005f;
    }
  }
  svg {
    margin-right: 20px;
  }
}

.bottomPart {
  margin-top: 30px;
}

.errorTitle {
  text-align: center;
  color: #ff005f;
  text-align: center;
  font-family: "Montserrat-Bold";
  font-size: 16px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: 3px;
}

.roundedArrowUpIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
