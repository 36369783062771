// Monserrat Light 300
@font-face {
  font-family: "Montserrat-Light";
  src:
    local("Montserrat-Regular"),
    url("../assets/fonts/Montserrat-Light.ttf") format("truetype");
}

// Monserrat Regular 400
@font-face {
  font-family: "Montserrat-Regular";
  src:
    local("Montserrat-Regular"),
    url("../assets/fonts/Montserrat-Regular.ttf") format("truetype");
}

// Monserrat Medium 500
@font-face {
  font-family: "Montserrat-Medium";
  src:
    local("Montserrat-Medium"),
    url("../assets/fonts/Montserrat-Medium.ttf") format("truetype");
}

// Montserrat SemiBold 600
@font-face {
  font-family: "Montserrat-SemiBold";
  src:
    local("Montserrat-SemiBold"),
    url("../assets/fonts/Montserrat-SemiBold.ttf") format("truetype");
}

// Montserrat Bold 700
@font-face {
  font-family: "Montserrat-Bold";
  src:
    local("Montserrat-Bold"),
    url("../assets/fonts/Montserrat-Bold.ttf") format("truetype");
}

// Inspiration
@font-face {
  font-family: "Inspiration-Regular";
  src:
    local("Inspiration-Regular"),
    url("../assets/fonts/Inspiration-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Hisdella-Regular";
  src:
    local("Hisdella-Regular"),
    url("../assets/fonts/Hisdella-Regular.ttf") format("truetype");
}

// Dosis Bold 700
@font-face {
  font-family: "Dosis-Bold";
  src:
    local("Dosis-Bold"),
    url("../assets/fonts/Dosis-Bold.ttf") format("truetype");
}

// GreatVibes-Regular
@font-face {
  font-family: "GreatVibes-Regular";
  src:
    local("GreatVibes-Regular"),
    url("../assets/fonts/GreatVibes-Regular.ttf") format("truetype");
}
