.container {
  max-width: 500px;
  border-radius: 4px;
  margin: 0 auto;
  min-height: 90%;
  padding: 20px;
  background-color: #fff;
  color: #000;
  backdrop-filter: blur(15px);
  position: relative;

  h1 {
    color: var(--color);
    text-align: center;
    color: var(--color);
    font-family: "Montserrat-SemiBold";
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 30px;
  }

  .content {
    text-align: center;

    h1 {
      color: var(--color);
      text-align: center;
      color: var(--color);
      font-family: "Montserrat-SemiBold";
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 30px;

      &:first-child {
        margin-bottom: 50px;
        font-size: 16px;
      }
    }

    .labelWithCopy {
      display: flex;
      justify-content: center;

      div {
        cursor: pointer;
        position: absolute;
        right: 30px;
      }
    }

    .actionButtons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 15%;
      margin-bottom: 30px;
      max-width: 200px;
      margin: 0 auto;

      div {
        min-width: 120px;
        border-radius: 32px;
        padding: 12px 29px;
        background-color: rgba(255, 255, 255, 0.08);
        color: var(--color);
        display: flex;
        align-items: center;
        justify-content: space-around;
        cursor: pointer;
        border: 1px solid var(--color);
        margin-bottom: 20px;
      }
    }
  }

  .afterClosedContent {
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 0 auto;

    .title {
      color: #000;
    }

    .hint {
      font-size: 18px;
      font-family: "Montserrat-Regular";
    }

    .icon {
      margin: 10% 0px;
    }

    .label {
      text-align: center;
      font-family: "Montserrat-Bold";
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-top: 50px;
      margin-bottom: 20px;
    }
    .button {
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 1180px) {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.25);
  }
}
