.mapContainer {
  // height: calc(100dvh - 230px) !important;
  height: 100%;
  width: 100%;
}

.customMarker {
  width: 100px;
  min-height: 53px;
  background-color: #ff6333;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  border-radius: 8px;
  box-shadow: 0 0.125rem 1.25rem #fac0ae !important;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-top: 14px solid #f76334;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
  }
}
.label {
  width: 85%;
  font-size: 9px;
  font-family: "Montserrat-Regular";
  padding: 0px 5px;
}
.price {
  width: 85%;
  font-size: 12px;
  font-family: "Montserrat-SemiBold";
  padding: 0px 5px;
}

@media screen and (max-width: 1180px) {
  .mapContainer {
    height: calc(100dvh - 160px) !important;
  }
}
