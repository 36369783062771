.wrapper {
  background-color: #fff;
  color: var(--color);
  max-width: 900px;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  margin: 0px auto 20px auto;
  min-height: calc(100dvh - 220px);

  h1 {
    font-family: "Montserrat-Medium";
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 20px;
  }
  h3 {
    font-family: "Montserrat-Medium";
    font-size: 18px;
    line-height: 28px;
    max-width: 90%;
    margin: 0 auto;
  }

  .fields {
    max-width: 550px;
    margin: 0 auto;
    padding-top: 40px;

    .salutations {
      padding: 20px 5px;
      display: flex;
      align-items: center;

      .salutationItem {
        width: 80px;
        padding: 10px;
        font-size: 15px;
        font-family: "Montserrat-Regular";
        color: #000;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .active {
        background-color: #600f835e;
      }
    }

    .shownData {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-bottom: 10px;
      margin-top: 10px;
      text-align: left;

      .oneCell {
        width: 250px;
        margin-top: 20px;

        .label {
          font-family: "Montserrat-Regular";
          font-size: 18px;
          color: #000;
        }
        .emailField {
          max-width: 90%;
          word-wrap: break-word;
          font-size: 22px;
          font-family: "Montserrat-Medium";
          line-height: 24px;
        }
        &:last-child {
          width: 100%;
        }
      }
    }

    .passwordField {
      input {
        max-width: 100% !important;
        color: var(--color);
      }
    }

    .bottomActions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        margin: 30px 0px;
      }
      .agreementHint {
        margin-top: 5%;
        color: #9b9b9b;
        font-family: "Montserrat-Regular";
        font-size: 18px;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .link {
          margin: 0px 4px;
          color: var(--color);
          cursor: pointer;
          word-break: break-word;
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .fields {
      .bottomActions {
        button {
          margin-bottom: 10px !important;
        }
      }
    }
  }
}
