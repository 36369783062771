.container {
  width: 60%;
  max-width: 600px;
  margin: 20px auto;

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      color: #000;
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .passcodeContainer {
      width: 100%;
      height: 274px;
      background-color: #fff;
      border-radius: 12px;
      margin: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      h3 {
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
      }

      .hint {
        color: var(--color);
        font-size: 14px;
        text-decoration: underline;
        cursor: pointer;
      }

      span {
        &:first-child {
          text-align: center;
          width: 90%;
        }
      }
    }

    .submitButton {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90%;
    .header {
      height: 40px;
    }

    .passcodeContainer {
      .hint {
        width: 80%;
        text-align: center;
      }
    }
  }
}
