.wrapper {
  .content {
    min-height: 84dvh;
    background-color: #fff;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    overflow-x: hidden;

    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      justify-content: space-around;

      .rightPart {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }

      .title {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
      }
      .imagePart {
        width: 30%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 50px;
          height: 150px;
          object-fit: contain;
        }
      }
    }

    .producerLabel {
      color: var(--color);
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Medium";
      font-size: 18px;
      font-style: normal;
      line-height: 20px;
      text-transform: uppercase;
      margin-top: 20px;
    }

    .middleLabels {
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 60px;

      div {
        &:nth-child(2) {
          font-family: "Montserrat-Bold";
        }

        &:last-child {
          font-size: 14px;
        }
      }
    }

    .labels {
      margin-left: 10px;
    }
    .noteText {
      position: relative;
      max-width: 90%;
      margin: 50px auto;
      text-align: justify;
      min-height: 50px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &:first-child {
        margin: 0 auto !important;
      }

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: -8px;
        background-color: var(--color);
      }

      .verticalText {
        transform-origin: left top 0;
        font-size: 16px;
        white-space: nowrap;
        text-transform: uppercase;
        cursor: vertical-text;
        position: absolute;
        display: block;
        height: auto;
        direction: rtl;
        left: -36px;
        z-index: 11;
        bottom: 0px;
        color: var(--color);
      }

      .data {
        display: flex;
        justify-content: space-between;
        max-width: 95%;
        text-align: left;

        .title {
          color: #000;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 25.511px;
        }

        div {
          &:first-child {
            width: 70%;

            div,
            span {
              width: 85%;
            }
          }

          &:last-child {
            width: 30%;
            min-width: 170px;

            div,
            span {
              width: 100%;
            }
          }
        }
      }

      .varietal {
        .title {
          color: #000;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 25.511px;
        }
      }
    }

    .deliveryDetails {
      color: var(--color);
      font-family: "Montserrat-Regular";
      font-size: 12px;
      line-height: 23.5px;
    }

    .priceLabel {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: var(--color);
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-SemiBold";
      font-size: 25px;
      font-style: normal;
      line-height: 16px;
      margin: 30px 0px;

      .discount {
        color: #ff6333;
        font-size: 12px;
        text-decoration: line-through;
      }
    }
  }
}
