.container {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 20px auto;

  h3 {
    width: 100%;
    text-transform: uppercase;
    font-size: 15px;
  }

  table {
    min-width: 330px;
    width: 100%;
    text-align: left;
    border-collapse: collapse;

    thead {
      tr {
        td {
          font-family: "Montserrat-Medium";
          padding: 10px;
          font-size: 12px;
        }
      }
    }

    th,
    td {
      padding: 8px;
    }
    tbody tr:nth-child(odd) {
      background-color: #f2f2f2;
    }

    tbody tr:nth-child(even) {
      background-color: #ffffff;
    }

    .fixedWidth {
      width: 100px;
    }
    .wideColumn {
      width: auto;
    }

    .currentClientRow {
      background-color: #ff6333 !important;
      color: #fff;
    }
  }
  .footerButton {
    min-width: 330px;
    width: 100%;
    height: 42px;
    border: 1px solid #ff6333;
    color: #ff6333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    cursor: pointer;
    transition: 0.1s linear;

    &:hover {
      background-color: #ff6333;
      color: #fff;
    }
  }

  @media screen and (max-width: 1180px) {
    .wideColumn {
      min-width: 150px;
    }
  }
}
