.chartWrapper {
  position: relative;
  height: 100%;
  margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      margin: 0px;
    }

    .refreshIcon {
      height: 30px;
      cursor: pointer;

      svg {
        height: 30px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    height: 86%;
  }

  @media screen and (max-width: 400px) {
    height: 80%;
  }
}
