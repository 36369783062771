@import "/src/styles/colors";

.mainInput {
  border-top: 0;
  border-right: 0;
  border-bottom: 0px;
  padding: 5px 5px 0px 5px;
  min-height: 30px;
  background-color: transparent;
  color: var(--color);
  font-family: "Montserrat-Medium";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  caret-color: #600f83;
  border-radius: 0px;

  &::placeholder {
    color: var(--color);
  }
}

.mainInput:focus,
.checkboxAndRadio:focus {
  outline: none;
}

.inputSection {
  display: flex;
  flex-direction: column;
}

.checkboxOrRadioSection {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 0px;
  cursor: pointer;
}

.checkboxOrRadioInput {
  opacity: 0.8;
  width: 16px;
  height: 16px;
  accent-color: #202359;
  cursor: pointer;
}

.inputLabel {
  color: #000;
  cursor: pointer;
  margin: 15px 0px;

  @media screen and (max-width: 1180px) {
    margin: 20px 0px;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
