.bonusPageWrapper {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  h2 {
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 22px;
    font-family: "Montserrat-Regular";
  }

  .content {
    max-width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .icon {
      margin: 20px 0px 50px 0px;
    }

    .label {
      font-family: "Montserrat-Medium";
      font-size: 14px;
      color: var(--color);
      text-align: center;
      margin-bottom: 50px;
    }
    .label h2 {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 90%;
    margin: 70px auto;

    .successStep {
      margin-top: 50px;

      .icon {
        margin: 50px 0px;
      }
    }
  }
}
