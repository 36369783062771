.contentWrapper {
  .header {
    display: flex;
    width: 200px;
    margin: 20px;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .table {
    width: 50%;
    margin: 0 auto;

    h3 {
      color: #ff6333;
    }
    h1 {
      font-size: 40px;

      margin-top: 20px;
    }

    .total {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 50px;

      h1 {
        margin: 0px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    margin-bottom: 50px;

    .header {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0px;

      img {
        width: 150px;
      }
    }

    .table {
      width: 90%;
      margin-top: 80px;
      h1 {
        font-size: 24px;
      }
    }
  }
}
