.timeline {
  position: relative;
  margin: 0 auto;
  padding: 40px 0;
  width: 90%;
  max-width: 1200px;

  .title {
    width: 80%;
    font-family: "Montserrat-SemiBold";
    font-size: 15px;
    text-align: center;
    margin: 20px auto 50px auto;
  }
}

.timelineRow {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.timelineItem {
  width: 45%;
  padding: 20px;
  position: relative;
  border-radius: 6px;

  span {
    font-size: 12px;
  }
}

.timelineItem.left {
  text-align: right;
}

.timelineItem.right {
  text-align: left;
}

.timelineDot {
  width: 20px;
  height: 20px;
  background: var(--color);
  border-radius: 50%;
  z-index: 1;
  position: relative;
}

@media screen and (max-width: 1180px) {
  .timelineRow {
    margin-bottom: 0px;
  }

  .timelineDot {
    height: 15px;
  }
}
