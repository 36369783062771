.wrapper {
  background-color: #fff;
  h1 {
    display: flex;
    justify-content: center;
    padding: 40px;
  }

  @media screen and (max-width: 1180px) {
    center {
      width: 90%;
      margin: 0 auto;
      word-wrap: break-word;
    }
  }
}
