.wrapper {
  padding-bottom: 50px;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    margin: 0 auto;
    color: #000;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-Bold";
    font-size: 18px;
    font-weight: 700;
    line-height: 32px;
  }

  .fields {
    max-width: 500px;
    margin: 50px auto 20px auto;

    h2 {
      font-family: "Montserrat-SemiBold";
      font-size: 18px;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        &:last-child {
          display: flex;
          align-items: center;
          color: var(--color);

          svg {
            margin-right: 5px;
          }
        }
      }

      .firstIcon {
        cursor: pointer;
        display: flex;
        align-items: center;
        color: var(--color);
        margin-right: 10px;

        svg {
          margin-right: 5px;
        }
      }
    }

    .addressOptions {
      display: flex;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;

      .radio {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid var(--color);
        margin-right: 10px;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto 20px auto;
  }

  @media screen and (max-width: 1180px) {
    padding: 10px 10px 20px 10px;
    background: #fff;
    margin: 0px 10px;

    .fields {
      max-width: 80%;
      margin: 10px auto 20px auto;

      .header {
        justify-content: end;
        align-items: normal;
        flex-direction: column-reverse;
        margin-top: 10px;

        div {
          &:last-child {
            justify-content: flex-start;
            margin-top: 5px;
          }
        }
      }
    }
  }
}
