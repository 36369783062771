.magicMatchContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .content {
    width: 80%;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .animation,
    .gameInfo {
      width: 50%;
    }

    .animation {
      font-size: 80px;
      font-family: "Montserrat-Bold";
    }

    .gameInfo {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 300px;
      justify-content: space-around;
    }
  }

  .playQuizGameBox {
    min-width: 300px;
    width: 50dvw;
    height: 70dvh;
    min-height: 750px;
    max-height: 1000px;
    border-radius: 32px;
    background: radial-gradient(
        109.99% 110.24% at 117.11% 123.01%,
        var(--color),
        rgba(84, 10, 157, 0) 100%
      ),
      radial-gradient(141.11% 141.42% at 0% 0%, var(--color) 0%, #050008 100%);

    .header {
      height: 20px;
      padding: 20px;

      svg {
        cursor: pointer;
      }
    }

    .questionBox {
      color: #fff;

      h2 {
        color: #fff;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Bold";
        font-size: 40px;
        font-style: normal;
        letter-spacing: 0.5px;
        text-align: center;
      }

      .hint {
        margin-top: 10px;
        text-align: center;
      }
    }

    .question {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 150px;
      font-size: 16px;
      font-family: "Montserrat-Medium";
    }

    .answerOptions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      .option {
        width: 90%;
        min-height: 56px;
        border-radius: 16px;
        border: 1px solid #511c85;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 26px;
          padding: 20px;
        }
      }
    }

    .answeredCoinBox {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .answerBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .answerButton {
        width: 50%;
        height: 56px;
        border: 1px solid var(--color);
        border-radius: 12px;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: "Montserrat-Medium";
        transition: 0.2s ease-in-out;
        cursor: pointer;

        &:first-child {
          margin-right: 5px;
        }
      }
    }

    .earnedMedals {
      color: #fff;
      font-family: "Montserrat-Bold";
      font-size: 29.928px;
      font-style: normal;
      line-height: normal;
      text-align: center;
    }

    .notLoggedInUser {
      color: #fff;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      margin-bottom: 20px;
    }

    .submitAnswerButton {
      width: 250px;
      height: 48px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto 0px auto;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-SemiBold";
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
      cursor: pointer;
    }
  }

  .subtitle {
    display: flex;
    justify-content: center;
    text-align: center;
    color: #ff6333;
    font-family: "Montserrat-Medium";
    font-size: 16px;
  }

  .timeUpLabel {
    color: #ff4141;
    font-size: 14px;
    font-family: "Montserrat-Bold";
    text-align: center;
  }

  .gameBox {
    max-width: 60%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .timer {
      width: 200px;
      height: 40px;
      border-radius: 8px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;

      span {
        font-size: 14px;

        &:first-child {
          font-family: "Montserrat-Medium";
          margin-right: 5px;
        }

        &:last-child {
          font-family: "Montserrat-Bold";
        }
      }
    }

    .cardGrid {
      display: grid;
      margin-top: 40px;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 10px;
      width: 88vw;
      margin: 40px auto 0px auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 1180px) {
    .content {
      flex-direction: column;

      .gameInfo {
        height: 200px !important;
        padding-bottom: 50px;

        div {
          h1 {
            margin-top: 0px;
            padding-top: 0px;
          }
        }
      }

      .animation,
      .gameInfo {
        width: 100% !important;
      }
    }

    .gameBox {
      max-width: 90%;
    }
    .playQuizGameBox {
      width: 88dvw;
      max-width: unset;
      padding-bottom: 20px;
    }
  }
}
