@import "/src/styles/colors";

.changePhoneNumberWrapper {
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .fields {
    max-width: 80%;
    h2 {
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-size: 18px;
    }

    input {
      width: unset;
    }

    .singleInput {
      margin-top: 30px;
      span {
        color: var(--color);
      }
      .phoneInputs {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        overflow: hidden;
        margin: 0 auto;

        .countryInput {
          width: 40%;
          position: relative;
          &::before {
            content: attr(data-chosen-value);
            position: absolute;
            bottom: 5px;
            left: 10px;
            font-family: "Montserrat-Medium";
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            pointer-events: none;
            color: var(--color);
          }

          select {
            color: transparent !important;
          }
        }
        label {
          pointer-events: none;
        }

        div {
          &:first-child {
            margin-right: 10px;
            min-width: 100px;
          }
        }

        .phoneInput {
          max-width: 150px;
        }
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 10%;
  }
  @media screen and (min-width: 1700px) {
    .phoneInputs {
      width: 90%;
    }
  }

  @media screen and (max-width: 1180px) {
    .fields {
      max-width: 100% !important;
    }
    .phoneInputs {
      margin: 0px 60px;

      .phoneInput {
        max-width: unset !important;
      }
      div {
        &:first-child {
          width: 40%;
        }
        &:last-child {
          width: 70%;
        }
      }
    }
    max-width: 80% !important;
    margin: 0 auto;
  }
}
