.walletWrapper {
  display: flex;
  min-height: 100%;

  .balanceContent {
    width: 98%;
    background-color: var(--color);
    border-radius: 8px;
    margin: 20px auto 0px auto;
    height: 165px;
    color: #fff;
    font-size: 18px;
    font-family: "Montserrat-SemiBold";
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    div {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-around;

      div {
        span {
          width: 100%;
        }

        &:first-child {
          text-align: left;
        }

        &:last-child {
          text-align: right;
        }
      }
    }
  }

  .balanceTabs {
    width: 98%;
    display: flex;
    justify-content: space-between;
    margin: 30px auto 0px auto;
    height: 41px;

    div {
      width: 33.3333333%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000;

      &:first-child {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }

      &:nth-child(2) {
        border-left: none;
        border-right: none;
      }

      &:last-child {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .leftContainer {
    position: relative;
    min-width: 310px;
    margin: 0 auto;
    overflow: auto;
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
    margin: 0px 5px;
    transition: width 0.3s linear;
  }
  .middleContainer {
    margin-right: 5px;
    padding-bottom: 10px;

    .header {
      display: flex;
      align-items: center;

      h2 {
        margin-left: 10px;
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 18px;
        margin-bottom: 0px;
      }
    }
  }

  .rightContainer {
    .header {
      display: flex;
      align-items: center;

      h2 {
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 18px;
        padding-bottom: 10px;
      }
    }
  }

  .rightContainer,
  .middleContainer {
    width: 0;
    overflow: auto;
    background-color: #fff;
    border-radius: 5px;
    transition: width 0.3s linear;
    position: relative;
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;
    padding: 0px 10px;

    .leftContainer {
      width: 98% !important;
      height: calc(100dvh - 130px);
      margin: 30px auto 0px auto;
      background-color: transparent;
      min-width: unset !important;
      padding: 0px !important;
    }
    .middleContainer,
    .rightContainer {
      display: none;
    }
  }
}
