.pageWrapper {
  max-width: 400px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 20px;
  height: 80%;
  justify-content: space-around;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  h2 {
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 22px;
    font-family: "Montserrat-Regular";
  }

  .successStep {
    max-width: 570px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    .icon {
      margin: 100px 0px;
    }
    h2 {
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 90%;
    margin: 0 auto;

    .successStep {
      margin-top: 50px;

      .icon {
        margin: 50px 0px;
      }
    }
  }
}
