.container {
  margin-bottom: 30px;

  .header {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px 0px;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    margin-top: 10px;

    .statusesContainer {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;

      .status {
        width: 90%;
        height: 40px;
        margin: 0 auto;
        border: 1px solid #000;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-family: "Montserrat-Medium";
        text-align: center;
        padding: 5px;

        &:first-child {
          margin-right: 20px;
        }
      }
    }

    .listOfReferralsLabel {
      width: 90%;
      height: 40px;
      margin: 0 auto;
      border: 1px solid #000;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-family: "Montserrat-Medium";
      text-align: center;
      margin: 10px auto;
      padding: 5px 0px;
      cursor: pointer;
      transition: 0.2s linear;

      &:hover {
        background-color: var(--color);
        color: #fff;
      }
    }

    .objectivesContainer {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 30px auto;

      div {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;

        .count {
          margin-right: 5px;
          font-size: 16px;
          font-family: "Montserrat-SemiBold";
        }
        svg {
          width: 40px;
          margin-right: 10px;
        }
      }
    }

    .nextLevelsContainer {
      width: 100%;
      height: 230px;
      background-color: #f8f9fb;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-transform: uppercase;
      margin: 0 auto;

      h3 {
        font-family: "Montserrat-SemiBold";
        font-size: 15px;
        width: 90%;
        margin: 0 auto 20px auto;
      }

      .cards {
        display: flex;
        justify-content: space-between;
        width: 90%;
        margin: 0 auto;

        .card {
          width: 33%;
          min-height: 90px;
          border-radius: 8px;
          display: flex;
          box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
          background-color: #fff;
          margin-right: 10px;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          .left {
            width: 30%;
            padding: 20px;
            font-size: 12px;
            word-break: break-all;
          }
          .right {
            width: 70%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            font-size: 16px;

            .upperContent {
              padding: 10px 0px 10px 0px;
              display: flex;
              align-items: center;

              svg {
                margin-right: 10px;
              }

              span {
                font-family: "Montserrat-Bold";
                font-size: 16px;
                color: var(--color);
                width: 100%;
                word-wrap: break-word;
              }

              .value {
                font-size: 12px !important;
              }
            }

            .bottomContent {
              font-family: "Montserrat-Medium";
              padding-right: 10px;
              padding-bottom: 10px;
            }
          }
        }
      }
    }

    .openTimelineBox {
      width: 100%;
      margin: 20px 0px 10px 0px;
      display: flex;
      justify-content: center;

      // svg {
      //   cursor: pointer;
      // }
    }
  }

  .tableCard {
    padding: 20px 0px;
    background-color: #fff;
    margin-top: 40px;
    border-radius: 8px;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  }

  @media screen and (max-width: 1180px) {
    .header {
      width: 95%;
      margin: 10px auto;

      .statusesContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .status {
          font-size: 15px;

          &:first-child {
            margin-right: 0px;
            margin-bottom: 10px;
            margin-left: 0px;
          }
        }
      }

      .objectivesContainer {
        height: 120px;

        div {
          width: 60px;
          height: 100px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;

          span {
            color: var(--color);
            word-wrap: normal;
          }

          svg {
            margin-right: 0px;
            width: unset !important;
          }

          div {
            display: flex;
            justify-content: space-evenly;
          }
        }
      }

      .nextLevelsContainer {
        text-align: center;
        justify-content: unset;
        padding-top: 20px;

        .cards {
          width: 98%;
          margin: 0px 8px;
          .card {
            height: 200px !important;
            flex-direction: column;

            .left,
            .right {
              width: 100% !important;
              padding: 15px 0px 0px 0px !important;
              margin-bottom: 5px;
              font-size: 12px !important;
            }

            .right {
              .upperContent {
                flex-direction: column;
                padding: 0px 10px;

                .value {
                  font-size: 12px !important;
                }

                svg {
                  width: 24px !important;
                  margin-right: 0px !important;
                  margin-bottom: 10px;
                  height: 26px;
                }
              }

              .bottomContent {
                padding: 0px 10px 10px 10px;
                word-wrap: break-word;
              }
            }
          }
        }
      }
    }

    .tableCard {
      width: 95%;
      margin: 10px auto;
    }
  }
}
