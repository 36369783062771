.container {
  width: 40%;
  background-color: #fff;
  border-radius: 4px;
  margin-top: 10px;
  padding: 20px;

  .header {
    display: flex;
    justify-content: space-between;
    div {
      cursor: pointer;
    }

    .clearDataBtn {
      display: flex;
      align-items: center;
      background-color: #ff6161;
      color: #fff;
      font-size: 10px;
      border-radius: 12px;
      padding: 5px 10px;
    }
  }

  h1 {
    font-size: 24px;
    margin-left: 30px;
    margin-top: 30px;
  }

  .actionButtons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .addButton {
      width: 50%;
      height: 48px;
      border: 1px solid #9da1ac;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 12px;
      font-family: "Montserrat-Medium";
      color: #9da1ac;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      text-align: center;
      padding: 5px 10px;

      &:first-child {
        margin-right: 10px;
      }

      &:hover {
        background-color: #9da1ac;
        color: #fff;
        border-color: #fff;
      }
    }
  }

  .selectedImageBox {
    background-color: #f5f6fa;
    height: 90px;
    border-radius: 4px;
    padding: 10px 20px;
    font-family: "Montserrat-Medium";
    display: flex;

    .image {
      width: 90px;
      height: 90px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .rightPart {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;

      .label {
        font-size: 10px;
      }

      .bottomButtons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 12px;

        div {
          cursor: pointer;
          display: flex;
          align-items: center;

          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }

  .secondDataTitle {
    margin-top: 50px;
  }

  .button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  @media screen and (max-width: 1180px) {
    width: 89%;
    margin: 0px auto 100px auto;
    background-color: transparent;
    height: unset;

    h1 {
      margin-top: 0px;
      margin-left: 0px;
      font-size: 22px;
    }

    .inputs {
      width: 100%;
      margin-left: 0px;
    }
  }
}

.inputs {
  width: 80%;
  margin-left: 30px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  input {
    background-color: #fff;
    height: 64px;
    border: none;
    border-radius: 4px;
    width: 100%;
    padding: 20px 15px 5px 15px;
    font-size: 16px;
    outline: none;
    margin: 5px 0px;
    font-family: "Montserrat-Medium";
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);

    &::placeholder {
      color: #000;
    }
  }

  .selectBox {
    position: relative;
    transition: 0.1s ease-in-out;

    .activeLabel {
      position: absolute;
      font-size: 12px;
      top: 18px;
      left: 15px;
    }

    .nullValueLabel {
      position: absolute;
      font-size: 16px;
      top: 33px;
      left: 15px;
    }
  }
  .selectInput {
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    height: 64px;
    border: none;
    border-radius: 4px;
    padding: 20px 15px 5px 15px;
    font-size: 16px;
    outline: none;
    margin: 10px 0px 5px 0px;
    font-family: "Montserrat-Medium";
    box-shadow: 0px 1px 6px 0px rgba(220, 225, 234, 1);
    color: #000;

    &::placeholder {
      color: var(--color);
    }

    &:disabled {
      opacity: 1;
    }
  }
}

.expandedImage {
  .header {
    display: flex;
    justify-content: flex-end;
    div {
      cursor: pointer;
    }
  }

  .imageBox {
    width: 100%;
    height: 100%;
    margin-top: 20px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.addCustomFieldContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .submitButton {
    margin: 50px 0px;
  }
  h2 {
    margin-top: 50px;
  }

  @media screen and (max-width: 1180px) {
    height: 50dvh;
    .inputs {
      margin-left: 0px;
    }
  }
}
