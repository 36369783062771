.playQuizGameBox {
  max-width: 450px;
  min-width: 300px;
  height: 80dvh;
  min-height: 600px;
  border-radius: 32px;
  background: radial-gradient(
      109.99% 110.24% at 117.11% 123.01%,
      var(--color),
      rgba(84, 10, 157, 0) 100%
    ),
    radial-gradient(141.11% 141.42% at 0% 0%, var(--color) 0%, #050008 100%);
  margin: 20px auto 0px auto;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .header {
    height: 20px;
    padding: 20px;

    svg {
      cursor: pointer;
    }
  }

  .content {
    text-align: center;
    padding: 0px 20px;

    h1 {
      font-size: 40px;
    }

    .subtitle {
      color: #fff;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Medium";
      font-size: 18px;
      font-style: normal;
      line-height: normal;
    }
  }

  .tasteOptionsWrapper {
    margin: 10px 0px;
    height: 370px;
    overflow: scroll;

    .tasteItem {
      width: 90%;
      height: 160px;
      border: 1px solid #511c85;
      border-radius: 20px;
      margin: 10px auto;
      padding: 5px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .colorOptions {
        width: 80%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-transform: uppercase;
        margin: 0 auto;

        .options {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .color {
            display: flex;
            position: relative;
            cursor: pointer;
            transition: width 0.2s ease-in-out;
            border-radius: 2px;

            .arrowIcon {
              position: absolute;
              top: -25px;
              left: 35%;
            }
          }
        }
      }

      .upperContainer,
      .bottomContainer {
        width: 80%;
      }
      .upperContainer {
        margin: 10px auto 10px auto;
        text-transform: uppercase;
      }
      .bottomContainer {
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
    }
  }

  .scrollDownLabel {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-transform: uppercase;
    margin: 20px auto;
    font-size: 10px;

    svg {
      margin-top: 10px;
    }
  }

  .rateTasting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 16px;
    border: 1px solid var(--color);
    height: 56px;
    margin: 80px 40px 0px 40px;
    padding: 0px 10px;
    position: relative;

    .option {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: 35px;
      height: 35px;
    }

    .icon {
      position: absolute;
      top: -40px;
    }

    .activeOption {
      border: 2px solid #ff6333;
      padding: 1px;
      border-radius: 20px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .labels {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;
    margin-top: 20px;
  }

  .nextButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
  }

  @media screen and (max-width: 1180px) {
    width: 88dvw;
    height: 90dvh;
    max-width: unset;

    .tasteOptionsWrapper {
      height: 410px;
    }
  }
}
