.addPhoneWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .fields {
    display: flex;

    div {
      flex-direction: unset !important;
    }
    select {
      max-width: 100px;
      margin-right: 30px;
    }

    .countryInput {
      width: 40%;
      position: relative;

      &::before {
        content: attr(data-chosen-value);
        position: absolute;
        bottom: 10px;
        left: 10px;
        color: var(--color);
        font-family: "Montserrat-Regular";
        font-size: 16px;
        font-style: normal;
        line-height: 24px;
        caret-color: #600f83;
        border-radius: 0px;
        border-left: 0px;
        outline: none;
        pointer-events: none;
      }

      .label {
        position: absolute;
        bottom: 10px;
        left: 0;
        color: var(--color);
      }
      select {
        color: transparent !important;
      }
    }
  }
  .buttonContainer {
    margin-top: 50px;
  }

  @media screen and (max-width: 1180px) {
    height: 50dvh;
    justify-content: space-between;
  }
}
