.wrapper {
  display: flex;

  .icon {
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .content {
    display: flex;
    align-items: flex-start;

    .title {
      font-size: 16px;
      font-family: "Montserrat-SemiBold";
      color: #000;
      margin-right: 10px;
      word-break: break-word;
      min-width: 50px;
    }
  }

  @media screen and (max-width: 1180px) {
    .content {
      flex-direction: column;

      .title {
        margin-bottom: 10px;
      }
    }
  }
}
