.card {
  perspective: 1000px;
  width: 100%;
  padding-bottom: 110px;
  cursor: pointer;

  @media screen and (max-width: 1180px) {
    height: 110px;
    padding-bottom: 0%;
  }
}

.inner {
  position: absolute;
  width: 100%;
  height: 110px;
  transform-style: preserve-3d;
  transition: transform 0.5s;
}

.flipped {
  transform: rotateY(180deg);
}

.front,
.back {
  position: absolute;
  width: 100%;
  height: 110px;
  backface-visibility: hidden;
  border-radius: 12px;
}

.front {
  background-color: transparent;
  transform: rotateY(180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.front img {
  width: 100%;
  height: 110px;
  border-radius: 12px;
  object-fit: contain;
}

.back {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
