.transactionsContainer {
  h1 {
    text-align: center;
  }

  .date {
    display: flex;
    justify-content: center;
    margin-top: 40px;

    .line {
      width: 30%;
      margin: 0px 25px;
    }
    .dateValue {
      width: 20%;
      text-align: center;
    }
  }

  .transaction {
    display: flex;
    justify-content: space-between;
    max-width: 80%;
    margin: 0 auto;
    padding: 10px;

    .title {
      color: var(--color);
      text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
      font-family: "Montserrat-Regular";
      font-size: 16px;
      line-height: normal;
      cursor: pointer;
    }

    .rightPartInfo {
      text-align: right;
      .price {
        color: var(--color);
        text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
        font-size: 16px;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
  .loadMoreButton {
    border-radius: 100px;
    border: 1px solid var(--color);
    width: 88px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px auto;
    cursor: pointer;
    color: var(--color);
    font-family: "Montserrat-Bold";
    font-size: 18px;
    line-height: normal;
  }

  .noItemsBox {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 18px;
      color: var(--color);
    }
  }

  @media screen and (max-width: 1180px) {
    margin-top: -20px;
  }
}
