.thankYouWrapper {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    color: var(--color);
    text-align: center;
    // font-family: "Inspiration-Regular";
    font-size: 50px;
    font-style: normal;
    line-height: normal;
    margin: 40px 0px;
  }

  .subtitle {
    color: #979797;
    text-align: center;
    font-family: "Montserrat-Regular";
    font-size: 18px;
    font-style: normal;
    line-height: normal;
    max-width: 80%;
  }

  .closeButton {
    margin: 50px 0px;
  }
}
