.wrapper {
  width: 100%;
  padding-bottom: 50px;

  .banner {
    width: 100%;
    height: 500px;
    border: 1px solid #000;
    color: #fff;
    display: flex;
    align-items: center;
    border-radius: 8px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .quotePart {
      font-size: 32px;
      margin-left: 10%;
      max-width: 440px;
    }

    @media screen and (max-width: 1180px) {
      background-position: center;
    }
  }

  .product {
    min-height: 300px;
    background-color: #fff;
    border: 1px solid rgba(151, 151, 151, 0.2078431373);
    border-radius: 8px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .leftInfo {
      width: 30%;
      display: flex;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .productImage {
        width: 150px;
        height: 280px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .middleLabels {
        .wineNameLabel {
          font-family: "Montserrat-SemiBold";
          font-size: 18px;
        }
        .vintageLabel {
          font-size: 18px;
        }
        .regionLabel {
          color: #797979;
          font-size: 14px;
          margin-top: 40px;
        }
      }
    }

    .middleInfo {
      width: 30%;
      padding: 0px 50px;

      .details {
        margin-top: 30px;
        font-size: 14px;
        line-height: 25px;

        .colored {
          color: var(--color);
        }
      }
    }

    .rightInfo {
      width: 40%;

      .top {
        margin-bottom: 20px;

        div {
          display: flex;
          justify-content: space-around;
          align-items: center;

          div {
            width: 33.33%;
            height: 40px;
            margin-bottom: 5px;

            &:first-child {
              display: flex;
              justify-content: flex-start;
            }

            &:nth-child(2) {
              width: 120px;
            }

            &:last-child {
              display: flex;
              justify-content: flex-end;
              width: 130px;
              text-align: right;
            }
          }
        }

        .bold {
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
        }

        .quantityActions {
          display: flex;
          border: 1px solid #979797;
          border-radius: 8px;

          span {
            position: absolute;
          }
          div {
            cursor: pointer;
            margin: 0px 5px;
          }
        }
      }

      .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid var(--color);
        border-radius: 12px;
        padding: 3px;

        div {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 20px;
          text-align: right;

          span {
            color: var(--color);

            &:last-child {
              font-family: "Montserrat-SemiBold";
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .productRating {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  .productSizes {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0px 20px 0px;

    .mobileProduct {
      width: 45%;
      min-height: 200px;
      border: 1px solid rgba(151, 151, 151, 0.2078431373);
      margin: 5px auto;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      position: relative;

      .leftContent {
        width: 50%;
        height: 73%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
          position: absolute;
          top: 10px;
          left: 10px;

          .region {
            font-size: 9px;
            word-break: break-all;
          }

          .productRating {
            color: var(--color);
            display: flex;
            align-items: center;
            font-size: 10px;
            svg {
              margin-right: 5px;
              width: 16px;
            }
          }
        }

        .name {
          color: #2f3447;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
          font-family: "Montserrat-SemiBold";
        }

        .category {
          color: #2f3447;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
        }

        .price {
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .bottleInfo {
          margin-bottom: 10px;
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-size: 10px;
          font-style: normal;
          line-height: 16px;
        }
      }

      .rightContent {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        img {
          height: 150px;
          object-fit: contain;
        }

        .specialProductImage {
          height: 280px;
        }
      }
    }

    .product {
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      background-color: #fff;
      margin-bottom: 10px;
      padding: 10px;
      color: #000;
      background-size: contain;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;
      width: 32%;
      height: 400px;

      .infoParts {
        width: 40%;
        height: 80%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .regionLabel {
          color: #797979;
          font-size: 14px;
          margin-top: 5px;
        }

        .bottoms {
          z-index: 999;

          .bookmarksIcon {
            height: 25px;
            width: 25px;
          }

          .productActions {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }

          .discount {
            font-size: 12px;
            color: #ff6333;
            text-decoration: line-through;
          }
        }
      }

      .imagePart {
        width: 40%;
        height: 100%;
        // margin-left: -30px;
        display: flex;

        align-items: center;

        .img {
          width: 130px;
        }

        &:hover {
          filter: blur(5px);
          & + .productInfo {
            width: 40% !important;
            opacity: 1;
            background-color: #f1f4f5;
            right: 0;
            height: 91%;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            border-radius: 5px;
            z-index: 99;
            color: #000;
            align-items: center;
            width: -webkit-fill-available;
            padding: 20px;

            .boldText {
              color: var(--color);
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: "Montserrat-SemiBold";
              font-size: 14px;
              font-style: normal;
              line-height: 19px;
              display: flex;
              align-items: center;

              &:first-child {
                margin-top: 20px;
              }
            }
          }
        }
      }

      .productImage {
        position: relative;
        object-fit: contain;
        width: 140px;
        z-index: 10;
      }

      .name {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 14.011px;
        margin-top: 20px;

        .vintageLabel {
          margin: 10px 0px;
          font-family: "Montserrat-Regular";
          font-size: 14px;
        }
      }

      .bottleSizeLabel {
        font-family: "Montserrat-Regular";
        font-size: 14px;
      }

      .price {
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 18px;
        font-style: normal;
        line-height: 17.237px;
        margin: 10px 0px;
      }

      .infoText {
        color: #fff;
        text-align: center;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 13.874px;
        font-style: normal;
        line-height: 17.237px;
      }

      .imagePart {
        position: relative;

        .bottleShadow,
        .specialBottleShadow {
          position: absolute;
          left: -146px;
          z-index: 8;
        }

        .bottleShadow {
          bottom: 40px;
        }
        .specialBottleShadow {
          bottom: 70px;
        }
      }

      .productInfo {
        position: absolute;
        bottom: 0px;
        right: -140px;
        color: #ffff;
        transition: right 0.2s linear;
        opacity: 0;
        height: 0px;
        font-size: 14px;

        div:first-child {
          max-width: 200px;
        }

        &:hover {
          width: 40% !important;
          opacity: 1;
          background-color: #f1f4f5;
          right: 0;
          height: 91%;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border-radius: 5px;
          z-index: 99;
          color: #000;
          align-items: center;
          width: -webkit-fill-available;
          padding: 20px;

          .boldText {
            color: var(--color);
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: "Montserrat-SemiBold";
            font-size: 14px;
            font-style: normal;
            line-height: 19px;
            display: flex;
            align-items: center;

            &:first-child {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .productWithDetailsOpen {
      width: 100% !important;
      max-width: 100% !important;
      height: 370px;
    }
  }

  .producerTitle {
    font-family: Montserrat-Medium;
    font-size: 24px;
    margin: 60px 10px 30px 5px;
  }

  .discount {
    font-size: 12px;
    color: #ff6333;
    text-decoration: line-through;
  }

  .addToCartButton {
    width: 48px;
    height: 48px;
    background-color: var(--color);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10px;
  }

  @media screen and (max-width: 1800px) {
    width: 90%;
  }

  @media screen and (max-width: 1280px) {
    width: 98%;
    margin: 0 auto;

    .productSizes {
      width: 95%;
      margin: 20px auto;

      .mobileProduct {
        width: 97%;
      }
    }

    .producerTitle {
      margin-left: 20px;
    }
  }

  @media screen and (max-width: 1180px) {
    margin-top: 20px;
    .product {
      flex-direction: column;
      padding: 10px;

      .leftInfo,
      .middleInfo,
      .rightInfo {
        width: 97%;
      }

      .middleInfo {
        padding: 0px 20px;
        margin-top: 30px;
      }
    }
  }
}
