@import "/src/styles/colors";
body,
html {
  margin: 0;
  padding: 0;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-size: cover;
  background-position: center;
  height: 100dvh;
  overflow: auto;
}
