@import "/src/styles/colors";

.container {
  display: flex;

  .content {
    width: 60%;
    max-width: 600px;
    margin: 0 auto;
  }

  .upperContainer {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;

    .picture {
      width: 100px;
      height: 100px;
      border: 1px solid rgba(250, 224, 214, 0.3);
      border-radius: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      object-fit: cover;

      span {
        color: #fff;
      }
    }

    .btn {
      text-align: center;
      cursor: pointer;
      font-family: "Montserrat-Medium";
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px; /* 137.5% */
    }
  }

  .personalInformation,
  .legalInformation,
  .settings {
    margin-top: 40px;

    h1 {
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
    }

    .operation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 3px solid #e4e4e4;
      padding-bottom: 15px;
      padding-top: 15px;
      cursor: pointer;

      .label {
        font-family: "Montserrat-Regular";
        font-size: 16px;
      }

      .clickable {
        margin-right: 20px;
      }
    }
  }

  .logoutButton {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }

    .link {
      color: #ff6333;
      cursor: pointer;

      a {
        text-decoration: underline;
        margin-left: 5px;
      }
    }

    .left {
      display: flex;
      div {
        &:first-child {
          margin-right: 50px;

          @media screen and (max-width: 1180px) {
            margin-right: 30px;
          }
        }
      }
    }
  }

  .link {
    cursor: pointer;
  }
  .authContent {
    margin-top: 50px;
    padding-bottom: 50px;
    h1 {
      text-align: center;
    }

    .invitedByLabel {
      display: flex;
      text-align: center;
      color: var(--color);
      margin-bottom: 30px;
    }

    .submitButton {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        max-width: 200px;
        margin-bottom: 30px;
      }

      a {
        cursor: pointer;
        font-size: 16px;
        font-family: "Montserrat-Regular";
      }
    }

    .colored {
      color: #ff6333;
      font-size: 16px;
      font-family: "Montserrat-Medium";
      text-align: center;
    }

    .signupMethods {
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      color: var(--color);
      text-decoration: underline;
      font-size: 16px;
      margin-top: 20px;
    }

    .agreementHint {
      margin-top: 5%;
      color: #9b9b9b;
      font-family: "Montserrat-Regular";
      font-size: 18px;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .link {
        margin: 0px 4px;
        color: var(--color);
        cursor: pointer;
        word-break: break-word;
      }
    }

    .emailField {
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;

      div {
        width: 90%;
      }
    }
  }

  .additionalItem {
    cursor: pointer;
    width: 30px;
    height: 30px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    border-radius: 2px;
    justify-content: center;
    font-size: 12px;
  }

  @media screen and (max-width: 1700px) {
    .authContent {
      overflow: auto !important;
    }
  }

  @media screen and (max-width: 1180px) {
    .content {
      width: 90%;
    }
    .header {
      height: 40px;
      margin-top: 20px;
    }

    .text {
      font-size: 16px !important;
    }

    .leftContainer {
      margin: 0;
      padding-bottom: 20px;
      background-color: transparent !important;
      width: 100% !important;
    }
    .rightContainer,
    .middleContainer {
      display: none;
    }

    .emailField {
      height: 107px;
      border-radius: 12px;
      margin: 100px 0px;
    }
  }
}
