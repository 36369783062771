.detailsWrapper {
  padding: 20px;

  .chart {
    background-color: #fff;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    h2 {
      color: var(--color);
      text-align: center;
      font-family: "Montserrat-Bold";
      font-size: 25px;
      font-weight: 700;
      line-height: 30px;
      margin-top: 20px;
      max-width: 80%;
    }

    h3 {
      color: var(--color);
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Bold";
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24.237px;
    }
  }
  .marketPriceContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 30px 0px;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    border-radius: 8px;
    padding: 5px;
    text-align: center;
    height: 195px;
    margin-left: -7px;
    background-color: #fff;

    .mainInfo {
      display: flex;
      justify-content: space-around;
      width: 90%;
      margin: 20px 0px;
      padding-bottom: 10px;

      .title {
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 14px;
        font-style: normal;
        line-height: 18px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dedede;
        margin-bottom: 10px;
        text-transform: capitalize;
      }
    }

    .marketPriceHint {
      padding: 0px 10px;
      color: #000;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 10px;
      font-style: italic;
      line-height: 14px;
    }
  }

  .textInfo {
    .header {
      width: 100%;
      text-align: center;
    }
    .historyInfo {
      position: relative;
      margin-top: 40px;
      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: -8px;
        background-color: var(--color);
      }
    }

    .appearanceInfo {
      position: relative;
      margin-top: 50px;
      min-height: 120px;
      display: flex;
      align-items: flex-end;

      &::after {
        content: "";
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: -8px;
        background-color: var(--color);
      }
    }

    .verticalText {
      -ms-transform: rotate(-90deg);
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      transform-origin: left top 0;
      font-size: 16px;
      white-space: nowrap;
      text-transform: uppercase;
      cursor: vertical-text;
      position: absolute;
      display: block;
      height: auto;
      direction: rtl;
      left: -30px;
      z-index: 11;
      bottom: -30px;
    }
  }

  @media screen and (max-width: 1180px) {
    padding: 15px;
    h2 {
      margin-top: 0px !important;
    }
  }
}
