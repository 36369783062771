@import "/src/styles/colors";

.mainWrapper {
  width: 100vw;
  background-size: cover;
  background-position: center;
  padding-bottom: 50px;
  position: relative;
  min-height: 100vh;

  .header {
    padding: 40px 120px;
  }

  .mainContent {
    max-width: 500px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1180px) {
    .header {
      padding: 20px;
      height: 10px;
      visibility: hidden;
    }
    .mainContent {
      max-width: 500px;
    }
  }
  @media screen and (max-width: 768px) {
    .mainContent {
      max-width: 330px;
    }
  }
}
