.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 40px auto 0px auto;

  h1 {
    width: 90%;
    margin: 0 auto;
  }

  .boxImage {
    width: 90%;
    height: 330px;
    background-image: url("../../../assets/images/invitation-box.png");
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 30px auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 12px;
    color: #fff;

    .header {
      display: flex;
      align-items: flex-start;
      width: 90%;

      svg {
        width: 35px;
        height: 35px;
        margin-right: 10px;
      }

      .left {
        display: flex;
        flex-direction: column;
        width: 80%;
        margin-right: 20px;
        text-align: left;

        h3 {
          margin: 0px;
          font-size: 22px;
        }

        .leftBottom {
          display: flex;
          font-size: 14px;
          margin-top: 10px;
        }
      }
    }

    .footer {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      div {
        &:first-child {
          width: 50%;
          text-align: center;
        }
      }

      img {
        width: 115px;
        height: 115px;
        border-radius: 8px;
      }
    }
  }
}
