.container {
  width: 90%;
  min-height: 70dvh;
  background-color: #fff;
  margin: 0 auto;
  border-radius: 12px;
  text-align: center;

  h1 {
    font-size: 48px;
    font-family: "Dosis-Bold";
    text-transform: uppercase;
    padding-top: 20px;
    margin-bottom: 0px;
  }

  .colored {
    color: #ff6333;
    font-size: 14px;
  }

  .subtitle {
    margin: 20px auto 10px auto;
    text-align: center;
    width: 70%;
    font-size: 14px;
  }

  .boxes {
    margin: 50px auto 0px auto;
    position: relative;
    width: 790px;
    min-height: 300px;

    span {
      margin: 0px 10px;
      &:nth-child(1) {
        margin-left: 0px;
      }
    }
    .first,
    .second,
    .third {
      width: 240px;
      height: 90px;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      padding: 0px 50px;
      position: absolute;
    }

    .first {
      background-color: #f2e6ff;
      border: 1px solid #3f007d;
      border-radius: 12px;
      font-size: 14px;
      font-family: "Montserrat-SemiBold";
      color: #3f007d;
      margin-bottom: 10px;
      left: 5%;
    }

    .second {
      background-color: #fff9d8;
      border: 1px solid #ffa450;
      border-radius: 12px;
      font-size: 14px;
      font-family: "Montserrat-SemiBold";
      color: #ffa450;
      margin-bottom: 10px;
      top: 115px;
      left: 30%;

      svg {
        width: 54px;
        height: 50px;
      }
    }
    .third {
      background-color: #deffd6;
      border: 1px solid #49bd36;
      border-radius: 12px;
      font-size: 14px;
      font-family: "Montserrat-SemiBold";
      color: #49bd36;
      right: 5%;
      top: -20px;
    }
  }
  .button {
    background-color: #ff6333;
    width: 240px;
    height: 48px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 50px;
    border-radius: 12px;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    color: #fff;
    margin: 0px auto;
    cursor: pointer;
  }

  @media screen and (max-width: 1180px) {
    min-height: 90dvh;

    h1 {
      font-size: 32px;
    }

    .boxes {
      width: 340px;
      display: flex;
      flex-direction: column;

      .first,
      .second,
      .third {
        width: 220px !important;
        position: unset;
      }

      .first,
      .second {
        margin: 5px auto;
      }
      .third {
        margin: 0px auto;
      }
    }

    .colored,
    .subtitle {
      font-size: 10px;
    }

    .button {
      width: 220px !important;
      margin-bottom: 0px;
      margin-top: 40px;
    }
  }
}
