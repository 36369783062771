.wrapper {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 30px auto;
  text-align: center;
  padding-bottom: 40px;
  .icon {
    margin: 50px 0px;
  }

  h1 {
    font-size: 20px;
    font-family: "Montserrat-Bold";
  }
  .content {
    margin-bottom: 40px;
    color: var(--color);

    ul {
      text-align: left;
    }

    .colored {
      color: #ff6333;
      font-size: 14px;
      margin-top: 50px;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90%;
  }
}
