@import "/src/styles/colors";

.mainButton {
  max-width: 200px;
  min-width: 110px;
  height: 50px;
  background: var(--color);
  color: $white;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  .loader {
    position: absolute;
    left: 47%;
    top: 10%;
  }
}
.mainButton:hover,
.loadingButton {
  opacity: 0.9;
}

.mainButton:disabled {
  opacity: 0.5;
  cursor: no-drop;
  pointer-events: none;
}
