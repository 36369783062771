.lineChartWrapper {
  height: 265px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  padding-right: 15px;
  margin-left: -7px;
  background-color: #fff;

  div {
    padding-top: 10px;
  }

  @media screen and (max-width: 1180px) {
    height: 400px;
  }
}
