.tableContainer {
  width: 100%;
  margin: 0 auto;
  overflow-x: auto;

  .list {
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;
    color: #333;

    th {
      padding: 10px;
      font-family: "Montserrat-Regular";
      font-size: 12px;
    }

    td {
      text-align: center;
      padding: 10px;
    }

    td:first-child {
      color: #ff6633;
      font-weight: bold;
      text-align: center;
    }

    .startingLevel {
      font-style: italic;
      text-align: center;
    }
  }

  tbody tr:nth-child(odd) {
    background-color: #f2f2f2;
  }
}

@media (max-width: 1180px) {
  .list {
    font-size: 14px;
    padding: 8px;
  }

  tr {
    text-align: center;
  }

  th,
  td {
    padding: 8px 5px;
  }

  th {
    font-size: 14px;
  }

  td {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .list {
    font-size: 12px;
  }

  th,
  td {
    padding: 6px 4px;
  }

  th {
    font-size: 12px;
  }

  td {
    font-size: 11px;
  }
}

@media (max-width: 375px) {
  .list {
    font-size: 10px;
  }

  th,
  td {
    padding: 5px 3px;
  }
}
