.contentWrapper {
  .headerTabs {
    display: flex;
    justify-content: space-between;

    .tab {
      width: fit-content;
      padding: 10px 20px;
      border: 1px solid #000;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }

      &:nth-child(2) {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }

    .searchBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      height: 41px;
      margin: 20px 0px;
    }
    .searchInput {
      width: 90%;
      height: 40px;
      display: flex;
      align-items: center;
      display: flex;
      align-content: center;
      transition: width 0.3s ease-in-out;
      border: 1px solid #979797;
      border-radius: 7px;

      svg {
        margin: 0px 10px;
      }
      input {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
        color: #000;
        font-family: "Montserrat-SemiBold";
        font-size: 12px;
        font-style: normal;
        text-transform: capitalize;

        &::placeholder {
          color: #000;
        }
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .headerTabs {
      justify-content: center;
      align-items: center;

      .tab {
        margin: 20px 0px;
        padding: 10px;
        text-align: center;
      }
    }
  }
}
