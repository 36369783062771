.content {
  height: 80%;
  margin: 0px auto 20px auto;
  padding: 30px 0px;

  .transaction {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;

    .upperItems {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;

      .transactionCategory {
        color: #000;
        font-family: "Montserrat-Medium";
      }

      svg {
        margin-right: 5px;
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .transaction:last-child {
    padding-bottom: 40px;
  }
  .loadMoreButton {
    max-width: 100px;
    font-size: 16px;
    border: 2px solid var(--color);
    border-radius: 100px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 40px auto;

    &:hover {
      background-color: var(--color) !important;
      color: #fff !important;
      border-color: #fff;
    }
  }
}

.header {
  display: flex;
  align-items: center;

  h2 {
    color: var(--color);
    font-family: "Montserrat-Bold";
    font-size: 18px;
    margin-top: 0px;
  }
}
