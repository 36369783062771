@import "/src/styles/colors";

.confirmByEmailWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .linkSentLabel {
    font-family: "Montserrat-Regular";
    font-size: 20px;
    max-width: 80%;
  }

  .codeInput {
    color: $main-color;
    margin: 10% 0px;
    max-width: 70%;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;

    div {
      width: 90%;
    }

    button {
      margin-top: 50px;
    }
  }

  .hintText {
    color: #868686;
    font-size: 16px;
    font-family: "Montserrat-Regular";
    max-width: 50%;
  }

  .resendEmail {
    color: $main-color;
    font-size: 16px;
    font-family: "Montserrat-Regular";
    text-decoration-line: underline;
    margin-top: 50px;
    cursor: pointer;
  }

  @media screen and (max-width: 1180px) {
    max-width: 70% !important;
    margin: 0 auto;

    .linkSentLabel {
      max-width: 80% !important;
    }
  }
}
