.content {
  .filtersWrapper {
    position: relative;
    margin: 20px 20px 20px 50px;
    padding-bottom: 50px;
    height: calc(100dvh - 100px);
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      align-items: center;
      .searchInput {
        width: 63%;
        height: 30px;
        display: flex;
        align-items: center;
        border-radius: 5px;
        border: 1px solid #d9d9d9;
        display: flex;
        align-content: center;
        transition: width 0.3s ease-in-out;
        color: #797979;

        svg {
          margin: 0px 10px;
        }
        input {
          width: 80%;
          background-color: transparent;
          border: none;
          outline: none;
          color: var(--color);
          font-family: "Montserrat-SemiBold";
          font-size: 12px;
          font-style: normal;
          text-transform: capitalize;

          &::placeholder {
            color: var(--color);
          }
        }
      }
    }
    .priceFields {
      width: 60%;
      margin: 60px 20px 20px 0px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .ttl {
        font-family: "Montserrat-SemiBold";
        font-size: 15px;
        margin-right: 10px;
      }

      .priceSlider {
        width: 300px;
        margin: 0px 20px;
      }
    }

    .filters {
      width: 90%;
      margin: 0px auto 0px auto;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      overflow-y: scroll;
      overflow-x: hidden;

      .item {
        width: 25%;
        height: 250px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        margin-top: 40px;
      }

      .title {
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        margin-bottom: 20px;
      }

      .filterInput {
        border: 1px solid #d9d9d9;
        height: 32px;
        border-radius: 8px;
        padding-left: 10px;
        outline: none;
      }
    }

    .rightSideButtons {
      display: flex;
      align-items: center;
      margin-top: 40px;
    }

    .value {
      input {
        color: #797979;
        border: 1px solid #d9d9d9;
        width: 100px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding-left: 10px;
        outline: none;
      }
    }

    .bottomActions {
      position: absolute;
      right: 40px;
      top: 80dvh;

      .clearFiltersBtn {
        padding: 0px 10px;
        width: 215px;
        height: 32px;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 10px;
        border: 1px solid #000;
        color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: 0.2s ease-in-out;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
      .mainBtn {
        width: 215px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 20px;
        background-color: #000;
        color: #fff;
        cursor: pointer;
        min-width: 150px;
        text-align: center;
        padding: 0px 10px;
        transition: 0.2s ease-in-out;
        border: 1px solid #000;

        &:hover {
          background-color: #fff;
          color: #000;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    .filtersWrapper {
      .bottomActions {
        top: 77dvh;
      }
    }
  }
}
