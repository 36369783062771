.lineChartWrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;

  svg {
    padding-top: 20px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    h2 {
      margin: 0px;
    }

    .refreshIcon {
      height: 30px;
      cursor: pointer;

      svg {
        height: 30px;
        padding: 0px;
      }
    }
  }
}
