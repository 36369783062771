@import "/src/styles/colors";

.container {
  max-width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media screen and (max-width: 1180px) {
    max-width: 90%;
  }
}

.checkboxLabel {
  margin-left: 10px;
  font-size: 16px;
  font-family: "Montserrat-Regular";
  color: var(--color);
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  min-width: 100px;
}

.round {
  position: relative;
  min-height: 30px;
}

.threeLabelsBox {
  display: flex;
  flex-direction: column;
  margin-left: 25px !important;
  margin-bottom: 0px !important;

  label {
    &:first-child {
      font-size: 16px;
      font-family: "Montserrat-SemiBold" !important;
    }

    &:nth-child(2) {
      font-size: 14px !important;
      margin: 5px 0px !important;
      color: #000 !important;
      font-family: "Montserrat-Regular" !important;
    }

    &:last-child {
      font-family: "Montserrat-Regular" !important;
      font-size: 14px !important;
      color: var(--color) !important;
    }
  }
}

.roseGlowCheckbox {
  display: flex;
  margin-left: 25px !important;
  margin-bottom: 0px !important;

  .roseGlowLabel {
    font-size: 14px !important;
    font-family: "Montserrat-Bold" !important;
    color: #ffa19a !important;
    margin-left: 10px;
  }

  @media screen and (max-width: 1180px) {
    flex-direction: column;

    .roseGlowLabel {
      margin-left: 0px;
    }
  }
}

.round label {
  background-color: #fff;
  border: 1px solid var(--color);
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;

  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: var(--color);
  border-color: var(--color);
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
