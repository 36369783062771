.container {
  width: 60%;
  max-width: 600px;
  margin: 20px auto;

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      color: #000;
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .passcodeContainer {
      width: 100%;
      height: 274px;
      background-color: #fff;
      border-radius: 12px;
      margin: 20px 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;

      h3 {
        font-family: "Montserrat-SemiBold";
        font-size: 16px;
      }

      .hint {
        color: #bababa;
        font-size: 14px;
        text-align: center;
      }

      .showPasscodeLabel {
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-family: "Montserrat-Medium";
          font-size: 12px;
        }

        div {
          margin-left: 5px;

          svg {
            height: 12px;
          }
        }
      }

      .bottomPart {
        width: 90%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        font-size: 14px;

        .forgotPasscodeLabel {
          cursor: pointer;
        }
      }
    }

    .saveButton {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90%;
    .header {
      height: 40px;
    }

    .passcodeContainer {
      .hint {
        width: 80%;
        text-align: center;
      }
    }
  }
}
