.container {
  max-width: 50dvw;
  margin: 0 auto;

  h2 {
    font-size: 25px;
    text-align: center;
    letter-spacing: 0.48px;
  }

  .table {
    width: 90%;
    min-height: 200px;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    border-radius: 8px;
    margin: 0 auto;
    padding: 10px 0px;

    table {
      width: 100%;
      border-collapse: collapse;
      table-layout: fixed;
      font-size: 12px;

      thead {
        tr {
          td,
          th {
            text-align: center;
            padding: 8px;
            border: none;
          }
        }
      }

      tbody {
        tr:nth-child(odd) {
          background-color: #f2f2f2;
        }

        tr:nth-child(even) {
          background-color: #ffffff;
        }

        td {
          text-align: center;
          padding: 8px;
        }
      }
    }
  }

  .nonScrollableTable {
    border: none;
    background-color: transparent;
  }

  .scrollableTbody {
    max-height: 500px;
    overflow-y: auto;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;

    table {
      width: 100%;
      text-align: center;
    }
  }

  .button {
    min-width: 300px;
    width: 95%;
    height: 42px;
    border: 1px solid #ff6333;
    color: #ff6333;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
    font-family: "Montserrat-SemiBold";
    font-size: 16px;
    cursor: pointer;
    transition: 0.1s linear;

    &:hover {
      background-color: #ff6333;
      color: #fff;
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: unset;

    .scrollableTbody {
      max-height: 300px;
    }
  }
}
