.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 50px;

  .card {
    border-radius: 12px;
    background-color: #fff;
    font-size: 16px;
    margin-bottom: 40px;

    .resendEmailBtn {
      color: #bababa;
      font-size: 14px;
      margin-top: 100px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1180px) {
    .card {
      width: 90%;
      height: 270px;
      margin: 0 auto 40px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      span {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
