.main {
  width: 100%;

  .header {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 30px auto;

    h2 {
      font-family: "Montserrat-Medium";
      font-size: 24px;
    }

    span {
      font-family: "Montserrat-Medium";
      font-size: 16px;
      color: #a5a5a5;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h5 {
      margin-bottom: 0px;
      font-size: 15px;
      font-family: "Montserrat-Bold";
    }

    img {
      width: 280px;
      height: 280px;
    }

    .manualEntryCode {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .title {
        margin-bottom: 20px;
      }

      .code {
        width: 100%;
        display: flex;
        justify-content: space-between;
        background-color: #a5a5a52b;
        height: 70px;
        border-radius: 12px;
        padding: 0px 20px;
        align-items: center;

        span {
          width: 80%;
          word-wrap: break-word;
        }
        .copyIcon {
          cursor: pointer;
        }
      }
    }

    .partTwo {
      margin-top: 40px;
    }
    .twoFactorInput {
      width: 320px;
      height: 56px;
      background-color: #f5f5f5;
      border: 1px solid var(--color);
      border-radius: 8px;
      margin-top: 20px;

      input {
        width: 92%;
        height: 100%;
        background-color: transparent;
        border-color: transparent;
        outline: none;
        font-size: 20px;
        padding: 0px 10px;
        text-align: center;

        &::placeholder {
          color: var(--color);
          text-align: center;
        }
      }
    }

    .button {
      margin-top: 50px;
    }
  }

  .deactivateTwoFactorContent {
    width: 95%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #000;
    font-size: 12px;
    font-family: "Montserrat-Medium";
    margin: 0 auto;

    svg {
      margin-bottom: 50px;
    }
  }
}
