.orderSummaryWrapper {
  background-color: #fff;
  width: 100%;
  min-height: 90%;

  .header {
    text-align: center;
    color: var(--color);
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 22px;
    font-style: normal;
    padding: 20px 0px;
    text-transform: uppercase;
  }

  .mobileHeader {
    width: 90%;
    margin: 0 auto;
    font-family: "Montserrat-Bold";
    font-size: 16px;
  }

  .card {
    background-color: #fff;
    height: 150px;
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    margin: 10px auto 10px auto;

    img {
      width: 100px;
      height: 80px;
      object-fit: contain;
    }

    .leftPart {
      width: 40%;

      .unitLabel {
        margin-top: 5px;
        span {
          color: #000;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-Medium";
          font-size: 18px;
          line-height: 7.822px;
        }
      }

      .productName {
        max-width: 80%;
        word-wrap: break-word;
      }
    }

    .rightPart {
      width: 60%;
      display: flex;
      justify-content: space-around;
    }

    .coloredLabel {
      margin-bottom: 5px;
      span {
        color: var(--color);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Bold";
        font-size: 16px;
        font-weight: 700;
        line-height: 22.111px;
      }
    }

    @media screen and (max-width: 1180px) {
      margin-bottom: 0px;

      .rightPart {
        text-align: center;
      }
    }
  }

  .mobileCard {
    width: 88%;
    height: 115px;
    margin: 10px auto 10px auto;
    background-color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 5px 10px;

    .topPart,
    .bottomPart {
      width: 98%;
      display: flex;
      margin: 0 auto;
    }

    .topPart {
      justify-content: space-between;

      .productName {
        width: 70%;
      }

      .qty {
        width: 30%;
        text-align: right;
      }
    }

    .bottomPart {
      justify-content: space-between;
      font-size: 18px;
      font-family: "Montserrat-SemiBold";
    }
  }

  .totalAmount {
    width: 90%;
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: #000;
    margin: 8px auto;
    padding: 0px 20px;
    color: #fff;
    border-radius: 4px;

    .bold {
      text-align: right;
      font-family: "Montserrat-Bold";
      font-size: 18px;
      font-style: normal;
      line-height: 20px;
      margin-left: 5px;
    }
  }

  .borderedBox {
    width: 90%;
    border: 1px solid #000;
    border-radius: 4px;
    margin: 8px auto;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px;
    justify-content: flex-end;
    align-items: flex-start;
    text-align: right;

    .borderedLabel {
      width: 100%;
      border: none;
      margin: 5px 0px;

      &:first-child {
        font-family: "Montserrat-SemiBold";
      }
    }
  }
  .orderDetails {
    width: 80%;
    display: flex;
    justify-content: center;
    margin: 50px auto;

    .leftPart,
    .rightPart {
      width: 50%;
    }
    .colored {
      color: var(--color);
      font-size: 18px;
      font-style: normal;
      line-height: 20px;
      padding-bottom: 5px;
      padding-bottom: 5px;
      padding-top: 10px;
    }
    .leftPart {
      display: flex;
      flex-direction: column;

      span {
        max-width: 80%;
      }

      .deliveryInfo {
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      div {
        display: flex;
        align-items: center;
      }

      .bold {
        color: var(--color);
        font-family: "Montserrat-Bold";
        font-size: 18px;
        font-style: normal;
        line-height: 29.5px;
        padding-right: 10px;
      }

      .dataRow {
        margin-top: 2px;
      }
    }

    .rightPart {
      div {
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;

        .bold {
          color: var(--color);
          font-family: "Montserrat-SemiBold";
          font-size: 18px;
          font-style: normal;
          line-height: 20px;
        }
      }
    }
  }

  .btn {
    display: flex;
    justify-content: center;
  }

  .emailSentLabel {
    text-align: center;
    color: var(--color);
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-size: 18px;
    font-style: normal;
    padding: 50px 20px;
  }

  @media screen and (max-width: 1180px) {
    margin-top: 20px;
    padding-bottom: 50px;
    background-color: transparent;

    .borderedLabel,
    .totalAmount {
      font-size: 14px;
    }

    .totalAmount {
      padding: unset;
      border: 1px solid #000;
      border-radius: 4px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 90%;
      height: 48px;
      background-color: #000;
      padding: 5px;

      span {
        color: #fff !important;
        font-size: 18px;

        &:first-child {
          margin-left: 10px;
          margin-right: 5px;
        }
      }
    }

    .borderedBox {
      width: 84%;
      padding: 10px 15px;
    }

    .totalAmount {
      width: 84%;
      padding: 0px 15px;
    }

    .card {
      width: 95%;
      height: unset;
      flex-direction: column;
      align-items: flex-end;

      .image {
        margin-right: 30px;

        img {
          width: 60px;
          height: 60px;
        }
      }
      .leftPart {
        width: 90%;
        margin-bottom: 10px;
      }
      .rightPart {
        width: 90%;
        justify-content: space-between;
        margin: 10px 0px;
      }
    }

    .orderDetails {
      flex-direction: column;
      width: 90%;

      .leftPart,
      .rightPart {
        width: 100%;
      }

      .leftPart {
        span {
          max-width: 100%;
        }
      }
      .rightPart {
        div {
          margin-top: 5px;
        }
        .date {
          width: 50%;
          display: flex;
          justify-content: flex-end;
          span {
            margin-left: 5px;
          }
        }
      }
    }
  }
}
