.gamificationWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .referredUserBox {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    position: relative;

    .icon {
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgb(96, 15, 131);
      background: linear-gradient(
        222deg,
        rgb(96, 15, 131) 0%,
        rgb(99, 26, 131) 34%,
        rgb(127, 48, 161) 70%,
        rgb(155, 62, 194) 100%
      );
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      box-shadow: 0px 1px 14px -8px rgba(0, 0, 0, 0.75);
    }

    .friendInfoContent {
      min-height: 100px;
      background-color: #fff;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      text-align: center;
      box-shadow: 0px 1px 14px -8px rgba(0, 0, 0, 0.75);
      display: flex;
      align-items: center;

      div {
        padding: 10px 20px;
      }

      span {
        font-family: "Montserrat-Bold";
        font-size: 18px;

        &:first-child {
          color: var(--color);
        }

        &:last-child {
          color: #000;
        }
      }
    }

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
    }
  }

  .gameContent {
    position: relative;
    margin-left: -100px;

    svg {
      width: 280px;
      height: 260px !important;
    }

    .bluredBox {
      width: 110px;
      height: 100px;
      position: absolute;
      top: 33%;
      left: 33%;
      transform: rotate(-30deg);
      fill: rgba(0, 0, 0, 0.3);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 10px;
    }

    .hint {
      position: absolute;
      top: 36%;
      left: 12%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 220px;

      span {
        color: #fff;
        text-align: center;
        font-family: "Montserrat-Bold";
        font-size: 18px;
        font-style: normal;
        line-height: 16px;
        letter-spacing: 0.5px;
      }

      img {
        width: 80px;
        height: 50px;
        margin-top: 20px;
      }
    }

    .alreadyPlayedQuiz {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 180px;

      .medals {
        color: #ccc;
        font-family: "Montserrat-Bold";
        font-size: 29px;
        font-style: normal;
        line-height: normal;
      }
      .wishLabel {
        color: #fff;
        text-align: center;
        font-family: "Montserrat-Bold";
        font-size: 24px;
        font-style: normal;
        line-height: 32px;
        letter-spacing: 0.5px;
        margin-top: 20px;
      }
    }
  }
  .alreadyPlayedLabels {
    width: 85%;
    color: var(--color);
    text-align: center;
    font-family: "Montserrat-Bold";
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: 0.5px;

    span {
      &:last-child {
        margin-top: 20px;
      }
    }
  }

  .playQuizGameBox {
    max-width: 390px;
    min-width: 300px;
    width: 30dvw;
    height: 70dvh;
    min-height: 680px;
    max-height: 1000px;
    border-radius: 32px;
    background: radial-gradient(
        109.99% 110.24% at 117.11% 123.01%,
        var(--color),
        rgba(84, 10, 157, 0) 100%
      ),
      radial-gradient(141.11% 141.42% at 0% 0%, var(--color) 0%, #050008 100%);

    .header {
      height: 20px;
      padding: 20px;

      svg {
        cursor: pointer;
      }
    }

    .questionBox {
      color: #fff;

      h2 {
        color: #fff;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Bold";
        font-size: 40px;
        font-style: normal;
        letter-spacing: 0.5px;
        text-align: center;
      }
    }
    .question {
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .answerOptions {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      .option {
        width: 90%;
        min-height: 56px;
        border-radius: 16px;
        border: 1px solid #511c85;
        margin: 10px 0px;
        display: flex;
        align-items: center;
        cursor: pointer;

        span {
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 26px;
          padding: 20px;
        }
      }
    }

    .answeredCoinBox {
      height: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .earnedMedals {
      color: #fff;
      font-family: "Montserrat-Bold";
      font-size: 29.928px;
      font-style: normal;
      line-height: normal;
      text-align: center;
    }

    .correctnessLabel {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 80%;
      margin: 30px auto 0px auto;
      text-align: center;

      span {
        font-size: 14px;
        font-family: "Montserrat-SemiBold";
      }
    }

    .notLoggedInUser {
      color: #fff;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      margin-bottom: 20px;
    }

    .submitAnswerButton {
      width: 60%;
      height: 48px;
      border-radius: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto 0px auto;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-SemiBold";
      font-size: 14px;
      font-style: normal;
      line-height: 20px;
    }
  }

  .content {
    width: 80%;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;

    .animation,
    .gameInfo {
      width: 50%;
    }

    .animation {
      font-size: 80px;
      font-family: "Montserrat-Bold";
    }

    .gameInfo {
      display: flex;
      align-items: center;
      flex-direction: column;
      height: 300px;
      justify-content: space-around;

      div {
        width: 100%;
      }

      h1 {
        margin-bottom: 10px !important;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .playQuizGameBox {
      width: 88dvw;
      max-width: unset;
      padding-bottom: 20px;
      height: 800px;
      min-height: unset;
      max-height: unset;
    }

    .gameContent {
      margin-left: 0px;
    }

    .content {
      flex-direction: column;

      .gameInfo {
        height: 200px !important;
        padding-bottom: 50px;

        div {
          h1 {
            margin-top: 0px;
            padding-top: 0px;
          }
        }
      }

      .animation,
      .gameInfo {
        width: 100% !important;
      }
    }
  }
}

.alreadyPlayedBox {
  .mainContentPopup {
    display: flex;

    svg {
      margin-right: 10px;
    }
  }

  .bottomPart {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;

    div {
      &:first-child {
        width: 80%;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
}
