.addEmailWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 350px;
  justify-content: space-between;

  .fields {
    display: flex;
    width: 260px;

    div {
      flex-direction: unset !important;
      width: 100%;
    }
  }
  .buttonContainer {
    margin-top: 50px;
  }

  @media screen and (max-width: 1180px) {
    height: 50dvh;
    justify-content: space-between;
  }
}
