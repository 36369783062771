.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
}
.switch input {
  visibility: hidden;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #787880bf;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: var(--color);
}
input:focus + .slider {
  box-shadow: 0 0 1px var(--color);
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
