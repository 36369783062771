@import "/src/styles/colors";

.personalDetailsWrapper {
  margin: 20px auto;
  width: 60%;
  max-width: 600px;

  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      color: #000;
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .content {
    border-radius: 12px;
    background-color: #fff;
    min-height: 500px;
    padding-top: 30px;

    .fields {
      width: 90%;
      margin: 0 auto;

      .topLabel {
        font-size: 16px;
      }

      .salutations {
        padding: 20px 5px;
        display: flex;
        align-items: center;

        .salutationItem {
          width: 80px;
          padding: 10px;
          font-size: 15px;
          font-family: "Montserrat-Regular";
          color: #000;
          margin-right: 20px;
          cursor: pointer;
          border-radius: 12px;
          border: 1px solid #bababa;
        }
        .active {
          background-color: #600f835e;
        }
      }
      h2 {
        border-bottom: 1px solid #20202035;
        padding-bottom: 10px;
        margin-bottom: 0px;
        font-size: 18px;
        font-family: "Montserrat-Semibold";
      }

      input {
        width: 100%;
        font-family: "Montserrat-Regular";
      }

      .input {
        margin: 20px 0px;
      }

      .selectInput {
        margin-bottom: 20px;
      }
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 70px;

    button {
      width: 150px;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90% !important;

    .content {
      min-height: 470px;
    }
  }
}
