@import "/src/styles/colors";

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 100vw;
  background-size: cover;
  background-position: center;
  height: 100dvh;
  overflow: hidden;

  .appHeader {
    min-height: 160px;
    display: flex;

    .logo {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 110px;
        margin-bottom: 30px;
      }
    }

    .title {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;

      h1 {
        max-width: 900px;
        color: #000;
        text-align: center;
        text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
        font-family: "Montserrat-Bold";
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        text-transform: uppercase;
      }
    }
  }

  .content {
    overflow: scroll;
  }

  @media screen and (max-width: 1180px) {
    .appHeader {
      display: none;
    }
    .content {
      margin-top: 20px;
    }
  }
}
