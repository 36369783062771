.content {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .latestTransactionsItem {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;

    .upperItems {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0px;

      .transactionCategory {
        color: #000;
        font-family: "Montserrat-Medium";
      }

      svg {
        margin-right: 5px;
      }

      div {
        display: flex;
        align-items: center;
      }
    }
  }

  .loadMoreTransactionsBtn {
    button {
      max-width: 100px;
      font-size: 16px;
    }

    button:hover {
      background-color: var(--color) !important;
      color: #fff !important;
      border-color: #fff;
    }
  }

  @media screen and (max-width: 1180px) {
    .latestTransactionsItem {
      margin-bottom: 40px;
    }
  }
}
