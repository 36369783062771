.pageWrapper {
  .header {
    width: 90%;
    display: flex;
    margin: 0 auto;
    padding-top: 20px;

    .leftPart {
      width: 50px;
    }
  }
  .middleLabels {
    width: calc(85% - 50px);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;

    span {
      &:first-child {
        font-size: 12px;
      }
      &:last-child {
        font-size: 22px;
        padding-top: 10px;
      }
    }
  }

  .tabs {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0px auto;
    div {
      width: 111px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000;

      &:first-child {
        border-right: none;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-left: none;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }
  }

  .content {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    .tableCard {
      padding: 20px 0px;
      background-color: #fff;
      margin-top: 40px;
      border-radius: 8px;
      box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    }

    .labels {
      width: 90%;
      margin: 0 auto;
    }

    .firstLabel {
      margin-bottom: 20px;
    }

    .title {
      width: 95%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000;
      border-radius: 2px;
      margin: 0 auto 30px auto;
    }

    .labelUnderTable {
      margin: 40px 0px;
      font-size: 15px;
      font-family: "Montserrat-Bold";
      text-align: center;
    }

    .cards {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto;

      .card {
        width: 50%;
        height: 134px;
        border-radius: 8px;
        display: flex;
        box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
        background-color: #fff;
        margin-right: 10px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 20px;

        .right {
          width: 70%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          font-size: 16px;

          .upperContent {
            padding: 10px 0px 10px 0px;
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }

            span {
              font-family: "Montserrat-Bold";
              font-size: 16px;
              color: var(--color);
              width: 100%;
              word-wrap: break-word;
            }

            .value {
              font-size: 12px;
              color: var(--color);
              text-transform: uppercase;
            }
          }

          .bottomContent {
            font-family: "Montserrat-Medium";
            padding-right: 10px;
            padding-bottom: 10px;
          }
        }
      }
    }

    .gamesContent,
    .medalsContent,
    .statusContent {
      margin-top: 50px;
      font-size: 16px;
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 1180px) {
    .tabs {
      font-size: 14px;
      max-width: 330px;
    }
    .content {
      width: 95%;
      margin: 10px auto;
      .gamesContent,
      .medalsContent,
      .statusContent {
        width: 95%;
        margin-top: 30px;
        text-align: justify;
        font-size: 16px;
      }

      .tableCard {
        margin-top: 20px;
      }

      .labelUnderTable {
        width: 90%;
        margin: 30px auto 10px auto;
        font-size: 12px;
      }
      .cards {
        width: 90%;

        .card {
          height: 170px;
          box-shadow: none;

          .value {
            min-height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
          }

          .right {
            width: 100%;
          }
          .upperContent {
            flex-direction: column-reverse;
            svg {
              margin-top: 10px;
              width: 24px;
              height: 24px;
            }

            .value {
              font-size: 10px !important;
            }
          }
          .bottomContent {
            color: var(--color);
            text-align: center;
            font-size: 10px;
          }
        }
      }
    }
  }
}
