.container {
  display: flex;
  align-items: flex-start;

  .productsWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding-bottom: 80px;

    .product {
      width: 33%;
      max-width: 401px;
      height: 370px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      background-color: #fff;
      margin: 5px;
      padding: 10px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      color: #000;
      background-size: contain;
      position: relative;
      overflow: hidden;

      .infoParts {
        width: 40%;
        height: 80%;
        text-align: left;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        .regionLabel {
          cursor: pointer;
          color: #797979;
          font-size: 14px;
          margin-top: 5px;
        }

        .bottoms {
          z-index: 999;
          .addToCartButton {
            width: 48px;
            height: 48px;
            background-color: var(--color);
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
          }

          .bookmarksIcon {
            height: 25px;
            width: 25px;
          }

          .productActions {
            width: 70%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;
          }

          .discount {
            font-size: 12px;
            color: #ff6333;
            text-decoration: line-through;
          }
        }
      }

      .imagePart {
        width: 40%;
        height: 100%;
        margin-left: -30px;
        display: flex;
        cursor: pointer;
        align-items: center;

        .img {
          width: 130px;
        }

        &:hover {
          filter: blur(5px);
          & + .productInfo {
            width: 40% !important;
            opacity: 1;
            background-color: #f1f4f5;
            right: 0;
            height: 91%;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            border-radius: 5px;
            z-index: 9999999;
            color: #000;
            align-items: center;
            width: -webkit-fill-available;
            padding: 20px;

            .boldText {
              color: var(--color);
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: "Montserrat-SemiBold";
              font-size: 14px;
              font-style: normal;
              line-height: 19px;
              display: flex;
              align-items: center;

              &:first-child {
                margin-top: 20px;
              }
            }
          }
        }
      }

      .productImage {
        position: relative;
        object-fit: contain;
        width: 140px;
        z-index: 10;
      }

      .productRating {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;

        svg {
          margin-right: 5px;
        }
      }

      .name {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
        font-style: normal;
        line-height: 14.011px;
        margin-top: 20px;
        cursor: pointer;

        .vintageLabel {
          margin: 10px 0px;
          font-family: "Montserrat-Regular";
          font-size: 14px;
          cursor: pointer;
        }
      }

      .bottleSizeLabel {
        font-family: "Montserrat-Regular";
        font-size: 14px;
        cursor: pointer;
      }

      .price {
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-SemiBold";
        font-size: 18px;
        font-style: normal;
        line-height: 17.237px;
        margin: 10px 0px;
        cursor: pointer;
      }

      .infoText {
        color: #fff;
        text-align: center;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 13.874px;
        font-style: normal;
        line-height: 17.237px;
      }

      .imagePart {
        position: relative;

        .bottleShadow {
          position: absolute;
          bottom: 35px;
          left: -147px;
          z-index: 8;
        }
      }

      .productInfo {
        position: absolute;
        bottom: 0px;
        right: -140px;
        color: #ffff;
        transition: right 0.2s linear;
        opacity: 0;
        height: 0px;
        font-size: 14px;

        div:first-child {
          max-width: 200px;
        }

        &:hover {
          width: 40% !important;
          opacity: 1;
          background-color: #f1f4f5;
          right: 0;
          height: 91%;
          bottom: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          border-radius: 5px;
          z-index: 9999999;
          color: #000;
          align-items: center;
          width: -webkit-fill-available;
          padding: 20px;

          .boldText {
            color: var(--color);
            font-feature-settings:
              "clig" off,
              "liga" off;
            font-family: "Montserrat-SemiBold";
            font-size: 14px;
            font-style: normal;
            line-height: 19px;
            display: flex;
            align-items: center;

            &:first-child {
              margin-top: 20px;
            }
          }
        }
      }
    }

    .productWithDetailsOpen {
      width: 100% !important;
      max-width: 100% !important;
      height: 370px;
    }

    .noProductsLabel {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      margin-top: 100px;
      color: var(--color);
    }

    .mobileProduct {
      width: 45%;
      height: 200px;
      border: 1px solid rgba(151, 151, 151, 0.2078431373);
      margin: 5px auto;
      border-radius: 12px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      position: relative;

      .leftContent {
        width: 50%;
        height: 73%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .header {
          position: absolute;
          top: 10px;
          left: 10px;

          .region {
            font-size: 9px;
          }

          .productRating {
            color: var(--color);
            display: flex;
            align-items: center;
            font-size: 10px;
            svg {
              margin-right: 5px;
              width: 16px;
            }
          }
        }

        .name {
          color: #2f3447;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
          font-family: "Montserrat-SemiBold";
        }

        .category {
          color: #2f3447;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
        }
        .price {
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          font-style: normal;
          line-height: 16px;
          margin-bottom: 10px;
        }

        .bottleInfo {
          margin-bottom: 10px;
          color: var(--color);
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-size: 10px;
          font-style: normal;
          line-height: 16px;
        }
      }

      .rightContent {
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        img {
          height: 150px;
          object-fit: contain;
        }
      }
    }

    .mobileProduct:nth-of-type(4n + 3),
    .mobileProduct:nth-of-type(4n + 3) + * {
      background-color: #97979735;
    }
  }

  .productDetailsWrapper {
    background-color: #fff;
    border-radius: 5px;
    width: 66%;
    margin: 5px 10px 5px 0px;
    padding: 0px 10px;
  }

  @media screen and (min-width: 2250px) {
    .productsWrapper {
      .product {
        width: 31%;
      }
    }
  }

  @media screen and (max-width: 2250px) {
    .productsWrapper {
      .product {
        width: 31%;
      }
    }
  }

  @media screen and (max-width: 1690px) {
    .productsWrapper {
      .product {
        width: 30%;
      }
    }
  }

  @media screen and (max-width: 1480px) {
    .productsWrapper {
      .product {
        background-size: cover;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    .productsWrapper {
      padding: 0px 10px 80px 10px;
      .rightContent {
        img {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .productsWrapper {
      .rightContent {
        margin-left: 10px;
        align-items: center;
        img {
          width: 95%;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .productsWrapper {
      .mobileProduct {
        width: 41%;
      }
    }
  }
  @media screen and (max-width: 380px) {
    .productsWrapper {
      .mobileProduct {
        width: 40%;
      }
    }
  }
}
