.wrapper {
  position: relative;
  .tabs {
    min-width: 500px;
    height: 70px;
    display: flex;
    align-items: center;
    background-color: var(--color);
    border-radius: 8px;
    padding: 0px 20px;
    color: #fff;
    margin: 0 auto;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);

    div {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      margin: 0 auto;

      .value {
        font-family: "Montserrat-Bold";
        font-size: 18px;
        line-height: 21px;
      }
    }
  }

  .border {
    width: 170px;
    height: 8px;
    border-radius: 6px;
    background-color: var(--color);
    margin-top: 7px;
    position: absolute;
    transition: left 0.2s linear;
  }

  @media screen and (max-width: 1180px) {
    .tabs {
      min-width: unset;
    }
    .border {
      width: 30dvw;
    }
  }
}
