.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .box {
    background-color: #fff;
    border-radius: 10px;
    height: 46dvh;
    margin: 5px;
    padding: 30px;
    color: var(--color);

    h2 {
      font-family: "Montserrat-Bold";
      font-size: 18px;
    }

    .actionContent {
      display: flex;
      height: 100%;
      justify-content: space-between;
      align-items: center;

      .label {
        width: 70%;
        margin-right: 10px;
      }

      button {
        max-width: 100px;
        font-size: 16px;
      }

      button:hover {
        background-color: var(--color) !important;
        color: #fff !important;
        border-color: #fff;
      }
    }
  }

  .wineCollectionBox {
    min-height: 46dvh;
    background-image: url("../../assets/images/wine_collection.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: #fff;
  }

  .latestTransactionsBox {
    background-color: #fff;
    border-radius: 10px;
    margin: 5px;
    padding: 30px;
    color: var(--color);

    h2 {
      font-family: "Montserrat-Bold";
      font-size: 18px;
      margin-top: 0px;
    }
  }

  .wineDetailsContainer,
  .wineCollectionContainer,
  .transactionDetailsTop {
    display: flex;
    margin: 5px;

    .highlightedBox {
      background-color: #fff;
      border-radius: 10px;
      padding: 20px;
      overflow: auto;

      h2 {
        margin-left: 10px;
        margin-top: 10px;
      }
    }

    .halfContainer {
      background-color: #fff;
      border-radius: 10px;
      overflow: auto;
    }
  }

  .transactionDetailsTop {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
  }

  .wineDetailsContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  .boxesBelow {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    width: 100%;
  }

  .twoBoxes {
    display: flex;
  }

  .smallerBox {
    width: 100% !important;
  }

  .biggerHeight {
    height: fit-content;
  }

  @media screen and (max-width: 1500px) {
    .smallerBox {
      width: 96% !important;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 100%;

    .box,
    .latestTransactionsBox {
      &:last-child {
        margin-bottom: 80px;
      }
    }
    .biggerHeight {
      height: fit-content;
    }
    .smallerBox {
      width: 100% !important;
    }

    .boxesBelow {
      grid-template-columns: 1fr;
      padding: 0px 10px;
    }
  }

  @media screen and (max-width: 768px) {
    .box {
      .actionContent {
        height: 70%;
        align-items: flex-start;
        flex-direction: column;
      }
    }
  }
}
