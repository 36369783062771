.wrapper {
  max-width: 50dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0px auto;

  h1 {
    font-size: 20px;
    margin-top: 20px;
  }

  .subtitle {
    margin: 30px 0px;
    color: var(--color);
  }

  .qrCode {
    margin: 50px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 144px;
    }
    span {
      margin-bottom: 20px;
      font-size: 14px;
      color: var(--color);
    }
  }

  .socialsContent {
    margin-top: 30px;

    .shareOnSocials {
      display: flex;
      margin: 30px 0px;
      div {
        width: 100px;
        height: 115px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: #fff;
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        margin: 0px 5px;
        border-radius: 12px;

        img {
          width: 28px;
          height: 28px;
        }

        span {
          font-size: 11px;
        }
      }
    }
  }

  .infoBtn {
    cursor: pointer;
  }

  @media screen and (max-width: 1180px) {
    max-width: 70dvw;
  }
}

.infoPopup {
  font-size: 12px;
  font-family: "Montserrat-Medium";
  color: #1a7fdc;
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 20px;
  }
  .bold {
    font-family: "Montserrat-Bold";
  }

  .table {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin-top: 50pxw;
    max-width: 400px;

    div {
      display: flex;
      flex-direction: column;
    }
  }
}
