.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  h2 {
    margin: 0px;
  }

  .refreshIcon {
    height: 30px;
    cursor: pointer;

    svg {
      height: 30px;
    }
  }
}
