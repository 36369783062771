.winesWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .wineItem {
    width: 210px;
    height: 375px;
    border-radius: 5.609px;
    background: #fff;
    box-shadow: 0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12);
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    background-image: url("../../../assets/images/wine_collection_bg.png");
    background-size: contain;
    position: relative;

    .bottleShadow {
      width: 100%;
      position: absolute;
      bottom: 130px;
      left: 0px;
      z-index: 9;
      object-fit: contain;
    }

    .imagePart {
      z-index: 999;
      img {
        width: 90px;
        height: 200px;
        object-fit: contain;
      }
    }

    span {
      color: #fff;
    }

    h3 {
      max-width: 80%;
      margin: 20px auto 0px auto;
      color: #fff;
    }
  }

  @media screen and (max-width: 1180px) {
    .wineItem {
      width: 45%;
      margin: 5px;
      height: 360px;
      background-size: cover;

      img {
        height: 150px;
      }

      .bottleShadow {
        bottom: 50px;
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  h2 {
    color: var(--color);
    font-family: "Montserrat-Bold";
    font-size: 18px;
    margin-top: 0px;
  }

  @media screen and (max-width: 1180px) {
    margin-left: 20px;
  }
}
