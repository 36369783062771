.card {
  background-color: #fff;
  height: 150px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
  position: relative;

  img {
    width: 100px;
    height: 100px;
    object-fit: contain;
  }

  .leftPart {
    width: 40%;

    .highlighted {
      margin-bottom: 5px;

      span {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-weight: 700;
        line-height: 22.111px;
      }
    }

    .unitLabel {
      margin-top: 5px;
      span {
        color: #000;
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 18px;
        line-height: 7.822px;
      }
    }
  }

  .rightPart {
    width: 60%;
    display: flex;
    justify-content: space-around;

    .coloredLabel {
      margin-bottom: 5px;
      span {
        color: var(--color);
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-weight: 700;
        line-height: 22.111px;
      }
    }

    .deleteItemIcon {
      cursor: pointer;
      margin-top: 10px;
    }
  }

  .quantityActions {
    display: flex;

    div {
      display: flex;
      align-items: center;
      cursor: pointer;
      &:first-child {
        margin-right: 10px;
      }

      &:last-child {
        margin-left: 10px;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    height: 255px;
    margin-right: 10px;
    justify-content: space-around;
    flex-direction: column;
    border-radius: 10px;

    .productMainInfos {
      width: 95%;
      height: 80%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .twoBoxes {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .colored {
          font-size: 12px;
        }

        .bottlePriceLabel {
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-size: 18px;
          font-family: "Montserrat-Medium";
          font-style: normal;
          line-height: 18px;
        }
        .blackBoldTitle {
          font-family: "Montserrat-Bold";
          font-size: 18px;
        }

        .totalPriceTitle {
          color: var(--color);
          text-align: right;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-Medium";
          font-size: 12px;
          font-style: normal;
          line-height: 18px;
        }
        .blackMediumTitle {
          color: #000;
          text-align: right;
          font-feature-settings:
            "clig" off,
            "liga" off;
          font-family: "Montserrat-Medium";
          font-size: 18px;
          font-style: normal;
          line-height: 18px;
        }
      }

      .wineNameLabel {
        font-feature-settings:
          "clig" off,
          "liga" off;
        font-family: "Montserrat-Bold";
        font-size: 16px;
        font-style: normal;
      }

      .vintageLabels {
        font-size: 14px;
        font-family: "Montserrat-Regular";
      }

      .titleLabels {
        width: 100%;
        font-size: 12px;
      }

      .image {
        width: 20%;
        display: flex;
        justify-content: center;
        margin-right: 10px;

        img {
          height: 85%;
          margin-top: 20px;
        }
      }

      .labels {
        width: 75%;
        height: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 10px 0px;
      }
    }

    .bottomPart {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .deleteItemIcon {
        svg {
          width: 30px;
        }
      }

      .quantityActions {
        width: 120px;
        height: 38px;
        align-items: center;
        justify-content: space-around;
        border: 1px solid #979797;
        border-radius: 8px;
      }
    }

    img {
      width: 70px;
      height: 80px;
    }

    .rightPart {
      text-align: center;

      .mobilePriceInfo {
        display: flex;
        flex-direction: column-reverse;

        .quantityActions {
          margin-top: 20px;
        }
      }
    }

    .leftPart {
      width: 50%;
    }
  }
}

.border {
  width: 100%;
  background-color: #fff;
  position: relative;
  display: flex;
  justify-content: center;

  &::after {
    content: "";
    position: absolute;
    height: 1px;
    width: 90%;
    background-color: var(--color);
  }
}
