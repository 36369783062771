@import "/src/styles/colors";

.closeButton {
  color: #a9abb4;
}
.closeButtonNoHeader {
  position: absolute;
  top: 10px;
  right: 10px;
}

.smContent {
  position: relative;
  max-width: 360px;
  margin-top: 3% !important;
  margin: auto;
  box-shadow: 0 0.125rem 0.25rem RGB(0 0 0/8%);
  border: none;
  padding: 20px;
  background: #fff;
  max-height: calc(100vh - 30%);
  overflow: auto;
  border-radius: 10px;
  outline: none;
}

.smInfoContent,
.smWarningContent,
.smInfoOrangeContent {
  position: relative;
  max-width: 360px;
  margin-top: 3% !important;
  margin: auto;
  box-shadow:
    inset 0px 2px 2px 0px #22c7fc,
    0px 4px 4px #a9abb4;
  border: none;
  padding: 20px;
  background: #fff;
  max-height: calc(100vh - 30%);
  overflow: auto;
  border-radius: 10px;
  outline: none;
}

.smErrorContent {
  position: relative;
  max-width: 360px;
  margin-top: 3% !important;
  margin: auto;
  box-shadow: 0px 2px 2px 0px #ff005f inset;
  border: none;
  padding: 20px;
  background: #fff;
  max-height: calc(100vh - 30%);
  overflow: auto;
  border-radius: 10px;
  outline: none;
}

.mdContent {
  position: absolute;
  width: 90%;
  height: 100%;
  right: auto;
  bottom: auto;
  transform: translate(20%, 10%);
  box-shadow: 0 0.125rem 0.25rem RGB(0 0 0 / 8%);
  border: none;
  padding: 0;
  background: $white;
  max-height: calc(100vh - 50px);
  overflow: hidden;
  outline: none;
}

.mdNoBgContent,
.mdNoBgNoCloseContent {
  position: absolute;
  width: 40%;
  right: auto;
  bottom: 60%;
  transform: translate(70%, 50%);
  border: none;
  padding: 0;
  max-height: calc(100vh - 50px);
  overflow: auto;
  outline: none;
}

.mdNoBgContent {
  background-color: #fff !important;
  min-height: 45dvh;
}

.modalHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infoHeader {
  display: flex;
  text-align: center;
  min-height: 60px;
  align-items: center;

  .title {
    width: 100%;
    text-align: center;
    font-family: "Montserrat-Bold";
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 3px;
  }
  .closeIcon {
    cursor: pointer;
    position: absolute;
    right: 20px;
  }
}

.modalClose {
  cursor: pointer;
}

.smWarningContent {
  min-height: calc(70dvh + 100px);
}

.smInfoContent {
  min-height: calc(70dvh + 100px);
}

.smInfoOrangeContent {
  min-height: 180px;
  background: #ff6333 !important;
  color: #fff;
}

@media screen and (max-width: 1180px) {
  .mdNoBgContent,
  .mdNoBgNoCloseContent {
    position: absolute;
    width: 100%;
    right: auto;
    bottom: 55%;
    transform: translate(0%, 50%);
    border: none;
    padding: 0;
    max-height: calc(100vh - 50px);
    overflow: auto;
    outline: none;
  }
  .modalClose {
    margin-left: 10%;
  }
  .mdNoBgContent {
    min-height: 65dvh;
  }
  .smContent,
  .smInfoContent,
  .smWarningContent,
  .smErrorContent,
  .smInfoOrangeContent {
    max-width: 320px;
  }
}
@media screen and (max-width: 768px) {
  .modalClose {
    margin-left: 0%;
  }
}

@media screen and (min-width: 960px) {
  .mdContent {
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    transform-origin: center;
    box-shadow: 0 0.125rem 0.25rem RGB(0 0 0/8%);
    border: none;
    padding: 0;
    background: $white;
    // max-height: calc(100vh - 150px);
    // overflow: scroll;
  }
  .smInfoContent {
    max-height: calc(100vh - 150px);
    max-width: 40dvw;
    padding: 20px;
  }
  .smContent {
    max-width: 500px;
  }
}
