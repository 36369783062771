.wrapper {
  .content {
    min-height: 84dvh;
    background-color: #fff;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;

    .card {
      width: 80%;
      min-height: 140px;
      background-color: #fff;
      border: 2px solid var(--color);
      border-radius: 8px;
      margin: 0px auto 40px auto;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .tableBox {
      width: 100%;
      overflow: scroll;
      border-radius: 8px;
      table {
        width: 100%;
        background-color: #f8f9fb;
        margin-top: 30px;
        border-radius: 8px;
        border-collapse: collapse;

        th {
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          text-align: left;
          border-bottom: 1px solid #000;
          border-spacing: 0px;
          padding: 30px 10px;
        }

        td {
          padding: 20px 10px;
          font-size: 14px;
        }
        .price {
          font-size: 14px;
          font-family: "Montserrat-SemiBold";
        }

        tfoot {
          background-color: #ff6333;

          td {
            text-transform: uppercase;
            font-family: "Montserrat-SemiBold";
            font-size: 14px;
            color: #fff;
          }
        }
      }
    }

    .mainButton {
      width: 100%;
      display: flex;
      justify-content: center;
      .reorderBtn {
        width: 200px;
        height: 40px;
        background-color: var(--color);
        color: #fff;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin: 20px 0px;
        float: right;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
