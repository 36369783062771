@import "./colors";

.btn {
  border-radius: 10px;
  font-size: 600;
  color: $white;
  border-color: none;
  transition: all 0.3s;
  width: 200px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    transition: all 0.3s;
    opacity: 0.9;
  }

  &.btn-primary {
    background-color: $main-color;
  }

  &.w-150 {
    width: 150px;
  }

  &.btn-primary-firstColour {
    background-color: $firstColour;
  }
  &.btn-primary-secondColour {
    background-color: $secondColour;
  }
  &.btn-primary-thirdColour {
    background-color: $thirdColour;
  }

  &.btn-primary-rounded {
    background-color: $main-color;
    border-radius: 40px;
  }

  &.btn-white-bordered {
    max-width: 200px;
    background-color: $white;
    border: 1px solid $main-color;
    font-family: "Montserrat-Regular";
    font-size: 16px;
    color: $main-color;
  }

  &.btn-lt-gray {
    background-color: $lt-gray;
    border-radius: 0px !important;
    color: $main-color;

    &:disabled {
      background-color: #e7e8ea;
      color: $main-color;
    }
  }

  &.btn-red {
    background-color: #fb2424;

    @media (max-width: 700px) {
      width: 100% !important;
    }
  }

  &.btn-transparent {
    background-color: transparent;
    border: 1px solid #dbdfea;
    color: #11131f;
  }

  &.btn-secondary {
    background-color: $secondary-color;
    color: #fff;
  }

  &.btn-icon {
    display: flex;
    align-items: center;
    width: fit-content;
    padding: 0px 10px;
    color: black;

    i {
      background-size: cover;
    }
  }

  &.w-100 {
    width: 100% !important;
  }
}

.underlinedBtn {
  color: #000;
  font-size: 16px;
  font-family: "Montserrat-Regular";
  text-decoration-line: underline;
  cursor: pointer;
}
