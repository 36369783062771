.accountClosureWrapper {
  max-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;

  .subTitle {
    font-size: 18px;
    font-family: "Montserrat-Bold";
  }

  .hint {
    font-size: 18px;
    font-family: "Montserrat-Regular";
  }

  .icon {
    margin: 10% 0px;
  }

  .button {
    margin-top: 50px;
  }
}
