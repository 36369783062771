.wrapper {
  padding-bottom: 20px;
  width: 90%;

  .multiSelectDropdownContainer {
    position: relative;
    max-height: 180px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;
    cursor: pointer;
    transition: height 0.3s ease-in-out;

    label {
      margin-bottom: 5px;
      font-size: 14px;
      input {
        margin-right: 10px;
      }
    }
  }

  .filterValue {
    width: 150px;
    height: 32px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    margin-bottom: 20px;
    padding-left: 10px;
    outline: none;
  }

  .inputCheckbox:checked {
    accent-color: var(--color);
  }

  @media screen and (max-width: 1180px) {
    .multiSelectDropdownContainer {
      width: 40%;
      height: 30px;
      background-color: rgba(255, 255, 255, 0.062745098);
      margin: 10px 4px;
      border-radius: 7px;
    }
  }
}
