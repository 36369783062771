.pageWrapper {
  width: 50%;
  height: 100%;
  background-color: #fff;

  .header {
    text-align: center;
    padding: 40px 20px;
  }

  .fields {
    max-width: 60%;

    margin: 0 auto;
    h2 {
      border-bottom: 1px solid #20202035;
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-size: 18px;
    }
  }

  .buttonContainer {
    margin: 100px auto 20px auto;
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 1180px) {
    width: 100%;
    margin: 0 auto;
  }
}
