.wrapper {
  padding-bottom: 30px;

  .header {
    display: flex;
    align-items: center;
    text-align: center;
    width: 100%;

    .title {
      margin: 0 auto;
      color: #000;
      text-align: center;
      font-feature-settings:
        "clig" off,
        "liga" off;
      font-family: "Montserrat-Bold";
      font-size: 18px;
      font-weight: 700;
      line-height: 32px;

      h2 {
        margin: 0;
      }
    }
  }

  .content {
    max-width: 400px;
    margin: 30px auto;
    .salutations {
      padding: 20px 5px;
      display: flex;
      align-items: center;

      .salutationItem {
        width: 80px;
        padding: 10px;
        font-size: 15px;
        font-family: "Montserrat-Regular";
        color: #000;
        box-shadow: 0px 2.1035857201px 2.1035857201px rgba(0, 0, 0, 0.25);
        margin-right: 20px;
        cursor: pointer;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
      .active {
        background-color: #600f835e;
      }
    }
  }

  .buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 80px auto 20px auto;
  }

  @media screen and (max-width: 1180px) {
    .fields {
      max-width: 80%;
    }

    .content {
      max-width: 300px;
    }
  }
}
