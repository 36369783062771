.container {
  width: 60%;
  max-width: 600px;
  margin: 20px auto;

  .header {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .previousIcon {
      cursor: pointer;
    }

    span {
      color: #000;
      text-align: right;
      font-family: "Montserrat-Regular";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-decoration-line: underline;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 50px;

    .card {
      width: 100%;
      max-width: 500px;
      background-color: #fff;
      height: 90px;
      border-radius: 12px;
      margin: 10px 0px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      font-size: 16px;
      font-family: "Montserrat-SemiBold";
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90%;
    .header {
      height: 40px;
    }
  }
}
