@import "/src/styles/colors";

.passwordInput {
  border-top: 0;
  border-right: 0;
  border-bottom: 0px;
  padding: 5px 0;
  width: 100%;
  min-height: 30px;
  background-color: transparent;
  font-family: "Montserrat-Medium";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  max-width: 90%;
  caret-color: #600f83;
  border-radius: 0px;
}
.passwordInput:focus {
  outline: none;
}
.password {
  width: 100%;
  position: relative;
}

.icon {
  position: absolute;
  right: 0;
  bottom: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.inputLabel {
  color: #000;
  margin: 15px 0px;
  display: flex;
  align-items: center;

  .infoIcon {
    cursor: pointer;
    margin-left: 40px;
  }

  @media screen and (max-width: 1180px) {
    margin: 20px 0px;
  }
}

.passwordInfo {
  display: flex;
  flex-direction: column;

  .hints {
    margin-top: 20px;
    white-space: pre-wrap;
  }
}
