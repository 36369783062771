.wrapper {
  background-color: #fff;

  @media screen and (max-width: 1180px) {
    center {
      width: 90%;
      margin: 0 auto;
      word-wrap: break-word;
      padding-bottom: 50px;
    }
  }
}
