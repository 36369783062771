@import "/src/styles/colors";

.privacyPolicyWrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 0px 20px;

  .datedLabel {
    color: $main-color;
    font-family: "Montserrat-Regular" !important;
    font-size: 15px !important;
    text-align: right;
  }

  .titleLabel {
    font-family: "Montserrat-Bold" !important;
    font-size: 25px;
    text-align: center;
  }

  .shownText {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    overflow: auto;

    span {
      font-family: "Montserrat-Regular" !important;
      font-size: 16px !important;
      margin-bottom: 20px !important;
      line-height: 25px !important;
      text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25) !important;
      text-align: justify !important;
      word-break: break-word;
    }
  }

  .actionCard {
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 165px;
    padding-top: 20px;
    margin: 0px 10px 10px 6px;

    .buttons {
      width: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      margin: 0 auto;
      padding: 30px 0px;
    }
  }

  @media screen and (max-width: 1180px) {
    max-width: 90%;
    margin: 0 auto;
    overflow: scroll;

    .titleLabel {
      max-width: 300px;
      margin: 20px auto;
      font-size: 18px;
    }

    .shownText {
      overflow: unset;
      height: unset;
    }

    .actionCard {
      .buttons {
        width: 100%;
        div {
          position: absolute;
          right: 20px;
          bottom: 20px;
        }
      }
    }
  }
}
