.container {
  .top {
    width: 100%;
    height: 80px;
    background-color: #000;
    display: flex;
    align-items: center;
    position: fixed;
    z-index: 99999999;

    .box {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;

      button {
        width: 125px;
        height: 48px;
        background-color: #fff !important;
        color: #000 !important;
        font-family: "Montserrat-SemiBold";
        font-size: 14px;
      }

      .rightButton {
        display: flex;
        justify-content: flex-end;
        position: relative;
        align-items: center;

        .badge {
          position: absolute;
          right: 5px;
          top: 3px;
        }

        .changeLanguageBtn {
          width: 25px;
          height: 25px;
          background-color: transparent !important;
          border: 1px solid #fff;
          font-family: "Montserrat-SemiBold";
          font-size: 14px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          border-radius: 2px;
          cursor: pointer;
          margin-right: 20px;
        }

        .checkoutIcon {
          width: 50px;
          height: 50px;
          border-radius: 12px;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px;
        }
      }
    }
  }

  .mainBanner {
    height: 100dvh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    .textContainer {
      width: 90%;
      margin: 0 auto;
    }
  }

  .textContainer {
    padding-top: 100px;
    display: flex;
    align-items: flex-start;

    .orangeLabel {
      width: 88px;
      height: 32px;
      background-color: #ff6333;
      color: #fff;
      border-radius: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      margin-right: 20px;
      margin-top: 5px;
    }

    .right {
      max-width: 60%;
      h1 {
        margin-top: 0px;
        margin-bottom: 20px;
        font-size: 32px;
        font-family: "Montserrat-SemiBold";
      }

      span {
        font-size: 16px;
        line-height: 16px;
      }
    }
  }

  .necktieSelection {
    width: 80%;
    min-height: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    .preview {
      width: 40%;
      height: 420px;
      .necktiePreview {
        background-color: var(--color);
        max-width: 425px;
        border-radius: 12px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
          width: 170px;
          height: 100%;
          object-fit: contain;
        }

        span {
          position: absolute;
          bottom: -30px;
          right: 0px;
          font-size: 16px;
          font-family: "Montserrat-SemiBold";
        }
      }
    }

    .sliderContainer {
      position: relative;
      width: 40%;

      .top {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;

        div {
          &:first-child {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }

          &:last-child {
            min-width: 80;
            height: 32px;
            background-color: #dbf6ff;
            border: 1px solid #1a7fdc;
            color: #1a7fdc;
            border-radius: 24px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px;
            font-family: "Montserrat-Medium";
          }
        }

        span {
          font-size: 12px;
        }

        h2 {
          font-size: 18px;
          font-family: "Montserrat-Bold";
          margin-top: 10px;
        }
      }
      .selected {
        border-left: 2px solid var(--color) !important;
        border-right: 2px solid var(--color) !important;
        border-bottom: 2px solid var(--color) !important;
      }
      .unselectedItem {
        cursor: pointer;
      }

      .necktieItem {
        flex: 0 0 auto;
        width: 150px !important;
        min-height: 220px;
        border: 2px solid transparent;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        text-align: center;
        display: flex !important;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: bold;
        transition: border-color 0.2s;
        box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
        background-color: #fff;
        position: relative;
        margin-top: 40px;
        margin-bottom: 10px;

        .necktieTop {
          position: absolute;
          top: -36px;
          left: -2px;
          width: 154px;
        }

        .deselect {
          position: absolute;
          top: -44px;
          right: 0px;
          background-color: var(--color);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          z-index: 9;
          color: #fff;
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }

        .content {
          width: 80%;
          padding: 10px;
          border-radius: 5px;
          font-size: 18px;
          font-family: "Montserrat-Bold";
          text-align: left;

          img {
            width: 70px;
            height: 120px;
            object-fit: contain;
          }
        }
        .bottomContent {
          min-height: 40px;
          width: 100%;
          font-size: 12px;
          font-family: "Montserrat-Medium";

          .selectedItem {
            color: #000 !important;
          }

          .necktieQuantity {
            height: 40px;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border: 1px solid #979797;
            border-radius: 12px;
            width: 79%;
            margin: 5px auto;

            div {
              display: flex;
              align-items: center;
              cursor: pointer;
            }
          }
        }
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }

  .prevArrow,
  .nextArrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background: #000;
    color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
    font-family: "Montserrat-SemiBold";
    font-size: 22px;
  }

  .prevArrow {
    left: -50px;
  }

  .nextArrow {
    right: -55px;
  }

  .wineSelection {
    width: 90%;
    margin: 0 auto;

    .tabs {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: 30px 0px;

      div {
        width: 130px;
        height: 32px;
        border: 1px solid #000;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0px 5px;
        cursor: pointer;
        font-size: 16px;
        font-family: "Montserrat-SemiBold";
        transition: all 0.2s linear;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }

    .products {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      .product {
        width: 32%;
        height: 400px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        text-align: center;
        border-radius: 8px;
        background-color: #fff;
        margin: 0px 10px 10px 0px;
        padding: 10px;
        color: #000;
        background-size: contain;
        position: relative;
        overflow: hidden;
        box-sizing: border-box;

        .infoParts {
          width: 40%;
          height: 80%;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .regionLabel {
            color: #797979;
            font-size: 14px;
            margin-top: 5px;
          }

          .bottoms {
            z-index: 999;

            .bookmarksIcon {
              height: 25px;
              width: 25px;
            }

            .productActions {
              width: 70%;
              display: flex;
              align-items: center;
              justify-content: space-between;

              .selectProductButton {
                min-width: 100px;
                height: 48px;
                background-color: var(--color);
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                color: #fff;
                font-size: 15px;
                margin-top: 10px;
              }

              .quantityActions {
                display: flex;
                width: 120px;
                height: 40px;
                align-items: center;
                justify-content: space-around;
                border: 1px solid #979797;
                border-radius: 8px;

                div {
                  display: flex;
                  align-items: center;
                  cursor: pointer;
                  &:first-child {
                    margin-right: 10px;
                  }

                  &:last-child {
                    margin-left: 10px;
                  }
                }
              }
            }
          }
        }

        .imagePart {
          height: 100%;
          margin-left: 25px;
          display: flex;
          cursor: pointer;
          align-items: center;

          .img {
            width: 130px;
          }

          &:hover {
            filter: blur(5px);

            & + .productInfo {
              width: 40% !important;
              opacity: 1;
              background-color: #f1f4f5;
              right: 0;
              height: 91%;
              bottom: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-evenly;
              border-radius: 5px;
              z-index: 99;
              color: #000;
              align-items: center;
              width: -webkit-fill-available;
              padding: 20px;

              .boldText {
                color: var(--color);
                font-feature-settings:
                  "clig" off,
                  "liga" off;
                font-family: "Montserrat-SemiBold";
                font-size: 14px;
                font-style: normal;
                line-height: 19px;
                display: flex;
                align-items: center;

                &:first-child {
                  margin-top: 20px;
                }
              }
            }
          }
        }

        .productImage {
          position: relative;
          object-fit: contain;
          width: 140px;
          height: 260px;
          z-index: 10;
        }

        .imagePart {
          position: relative;

          .bottleShadow,
          .specialBottleShadow {
            position: absolute;
            left: -146px;
            z-index: 8;
          }

          .bottleShadow {
            bottom: 42px;
          }
          .specialBottleShadow {
            bottom: 70px;
          }
        }

        .productInfo {
          position: absolute;
          bottom: 0px;
          right: -140px;
          color: #ffff;
          transition: right 0.2s linear;
          opacity: 0;
          height: 0px;
          font-size: 14px;
          pointer-events: none;

          div:first-child {
            max-width: 200px;
          }

          &:hover {
            width: 40% !important;
            opacity: 1;
            background-color: #f1f4f5;
            right: 0;
            height: 91%;
            bottom: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            border-radius: 5px;
            z-index: 99;
            color: #000;
            align-items: center;
            width: -webkit-fill-available;
            padding: 20px;
            pointer-events: all;

            .boldText {
              color: var(--color);
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: "Montserrat-SemiBold";
              font-size: 14px;
              font-style: normal;
              line-height: 19px;
              display: flex;
              align-items: center;

              &:first-child {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }

    .selectInput {
      width: 200px;
      background-color: #fff;
      height: 64px;
      border: none;
      border-radius: 4px;
      font-size: 16px;
      outline: none;
      padding: 0px 30px 0px 20px;
      margin: 30px auto;
      font-family: "Montserrat-SemiBold";
      font-size: 16px;
      color: #000;
      border: 1px solid #000;
      border-radius: 32px;
      display: flex;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 12 9" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.41 0.794832L7.86805e-07 2.20483L6 8.20483L12 2.20483L10.59 0.794833L6 5.37483L1.41 0.794832Z" fill="%23000"/></svg>')
        no-repeat right 15px center;
      background-size: 10px 9px;

      &::placeholder {
        color: var(--color);
      }

      &:disabled {
        opacity: 1;
      }
    }
  }

  .productRating {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }

  .name {
    color: #000;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-SemiBold";
    font-size: 14px;
    font-style: normal;
    line-height: 14.011px;
    margin-top: 20px;

    .vintageLabel {
      margin: 10px 0px;
      font-family: "Montserrat-Regular";
      font-size: 14px;
    }
  }

  .bottleSizeLabel {
    font-family: "Montserrat-Regular";
    font-size: 14px;
  }

  .price {
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-SemiBold";
    font-size: 18px;
    font-style: normal;
    line-height: 17.237px;
    margin: 10px 0px;
  }

  .infoText {
    color: #fff;
    text-align: center;
    font-feature-settings:
      "clig" off,
      "liga" off;
    font-family: "Montserrat-Medium";
    font-size: 13.874px;
    font-style: normal;
    line-height: 17.237px;
  }

  .infoParts {
    width: 40%;
    height: 80%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    .regionLabel {
      color: #797979;
      font-size: 14px;
      margin-top: 5px;
    }

    .bottoms {
      z-index: 999;

      .bookmarksIcon {
        height: 25px;
        width: 25px;
      }

      .productActions {
        width: 70%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .selectProductButton {
          min-width: 100px;
          height: 48px;
          background-color: var(--color);
          border-radius: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          color: #fff;
          font-size: 15px;
          margin-top: 10px;
        }

        .quantityActions {
          display: flex;
          width: 120px;
          height: 38px;
          align-items: center;
          justify-content: space-around;
          border: 1px solid #979797;
          border-radius: 8px;

          div {
            display: flex;
            align-items: center;
            cursor: pointer;
            &:first-child {
              margin-right: 10px;
            }

            &:last-child {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .discount {
    font-size: 12px;
    color: #ff6333;
    text-decoration: line-through;
  }

  .magnumContent {
    width: 90%;
    margin: 0px auto;

    .productSlider {
      width: 100%;
      background-color: #eff2f5;
      border-radius: 12px;
      margin: 50px 0px 10px 0px;
      position: relative;
      padding: 50px 0px;

      .prevArrow {
        left: 20px;
      }
      .nextArrow {
        right: 20px;
      }

      .product {
        width: 80% !important;
        display: flex !important;
        align-items: center;

        .imagePart {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          .bottleShadow {
            position: absolute;
            left: -146px;
            bottom: 15px;
            z-index: -2;
          }

          .productImage {
            object-fit: contain;
            height: 535px;
          }
        }

        .middlePart {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 400px;
          max-width: 400px;

          div {
            margin: unset !important;
          }
          .data {
            max-width: 95%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
              color: var(--color);
              font-feature-settings:
                "clig" off,
                "liga" off;
              font-family: "Montserrat-SemiBold";
              font-size: 14px;
              font-style: normal;
              line-height: 25.511px;
            }

            div {
              &:first-child {
                width: 70%;

                div,
                span {
                  width: 85%;
                }
              }

              &:last-child {
                width: 30%;
                min-width: 170px;

                div,
                span {
                  width: 100%;
                }
              }
            }
          }
        }

        .infoParts {
          width: 300px;
          height: 500px;

          .productRating,
          .bottoms,
          .productActions button,
          div:nth-of-type(2),
          .selectProductButton,
          .quantityActions,
          .quantityActions div {
            margin: unset !important;
          }

          .name {
            font-size: 32px;

            div {
              &:first-child {
                margin-bottom: 40px;
              }
            }
          }
          .price {
            margin: 40px 0px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      margin-bottom: 100px;

      button {
        font-size: 14px;
        font-family: "Montserrat-SemiBold";
        &:first-child {
          background-color: transparent !important;
          color: #000 !important;
        }

        &:last-child {
          background-color: #000 !important;
        }
      }
    }
  }

  @media screen and (max-width: 1500px) {
    .sliderContainer {
      width: 50% !important;
    }
  }

  @media screen and (max-width: 1200px) {
    .textContainer {
      padding-top: 40px;
      flex-direction: column;

      .right {
        max-width: 100%;
        margin-top: 20px;
      }
    }

    .mainBanner {
      height: unset !important;
      padding-bottom: 80px;

      .necktieSelection {
        flex-direction: column;
        margin-top: 30px;
        width: unset !important;

        .sliderContainer,
        .preview {
          width: 80% !important;
        }
        .bottomLabel {
          display: flex;
          justify-content: flex-end;
          color: #979797;
          margin: 10px 0px 40px 0px;
          font-size: 16px;
          font-family: "Montserrat-Medium";
          cursor: pointer;
          width: 90%;
        }

        .sliderContainer {
          display: flex;
          overflow-x: scroll;
          overflow-y: hidden;
          padding: 10px;
          gap: 1rem;
          height: 270px;
          align-items: flex-end;
          width: calc(100% - 40px) !important;

          .necktieItem {
            margin-top: 15px;

            &:nth-of-type(1) {
              margin-left: 20px !important;
            }

            &:last-child {
              margin-right: 20px;
            }
          }
        }

        .preview {
          .necktiePreview {
            span {
              right: unset !important;
            }
          }
        }
      }
    }

    .wineSelection {
      .products {
        flex-direction: column;

        .product {
          width: 100%;

          .imagePart {
            margin-left: 0px;
          }

          .productActions {
            width: 90% !important;
          }
        }
      }
    }

    .magnumContent {
      .productSlider {
        .infoParts {
          width: 40% !important;
        }

        .prevArrow,
        .nextArrow {
          top: unset;
          bottom: -100px;
        }

        .prevArrow {
          left: 100px;
        }
        .nextArrow {
          right: 100px;
        }

        .productActions {
          width: 200px;
        }

        .name {
          font-size: 26px !important;
          line-height: unset;

          br {
            display: none;
          }
          div {
            &:first-child {
              margin-bottom: 10px !important;
            }
          }
        }

        .price {
          margin: 20px 0px;
        }

        .imagePart {
          .productImage {
            width: 125px;
            height: 345px !important;
          }
        }

        .infoParts {
          height: 350px !important;
        }
      }

      .buttons {
        flex-direction: column;
        align-items: center;
        margin-top: 150px;
        button {
          &:first-child {
            margin-right: 0px !important;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
