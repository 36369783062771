@import "/src/styles/colors";

.changeLoginCredentialsWrapper {
  h1 {
    text-align: center;
    font-size: 20px;
  }
  .chlCContent {
    margin-top: 50px;
    width: 100%;
  }

  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    cursor: pointer;

    .item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .itemOrder {
        width: 30px;
      }
      .itemOrder {
        color: $main-color;
        font-family: "Montserrat-Bold";
        font-size: 16px;
      }

      .itemName {
        font-size: 18px;
        font-family: "Montserrat-SemiBold";
      }
    }
  }

  @media screen and (max-width: 1180px) {
    margin-top: 50px;

    .chlCContent {
      margin-top: 100px;
    }
    h1 {
      font-size: 20px;
      text-align: center;
    }

    .wrap {
      width: 80%;
      margin: 0 auto;
    }

    .item {
      width: 88% !important;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100% !important;
  }
}
