@import "/src/styles/colors";

.changeEmailWrapper {
  h1 {
    text-align: center;
    margin-bottom: 50px;
  }

  .fields {
    h2 {
      border-bottom: 1px solid #20202035;
      padding-bottom: 10px;
      margin-bottom: 0px;
      font-size: 18px;
    }

    input {
      width: unset;
    }
  }
  .buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 100px;
  }

  @media screen and (max-width: 1180px) {
    max-width: 70% !important;
    margin: 0 auto;
  }
}
