.wrapper {
  .item {
    width: 100%;
    height: 100px;
    border: 1px solid #dddddd;
    border-radius: 20px;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0px;

    .leftTitle {
      width: 100px;
      word-break: break-all;
      margin-left: 20px;
      font-size: 14px;
      font-family: "Montserrat-Bold";
    }

    .rightLabel {
      width: 60px;
      word-wrap: break-word;
      margin-right: 18px;
      font-size: 8px;
      text-align: left;
      text-transform: uppercase;
    }

    .colorOptions {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-transform: uppercase;

      .leftLabel {
        width: 50px;
        word-break: break-word;
        margin: 0px;
        font-size: 8px;
        text-align: right;
      }

      .options {
        width: 370px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px 20px;

        .color {
          display: flex;
          position: relative;
          cursor: pointer;
          transition: width 0.2s ease-in-out;
          border-radius: 2px;

          .arrowIcon {
            position: absolute;
            top: -25px;
            left: 35%;
          }
        }
      }
    }
  }
  .submitBtn {
    margin: 50px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 1300px) {
    width: 95%;
  }

  @media screen and (max-width: 1180px) {
    width: 98%;

    .item {
      height: 120px;
      flex-direction: column;
      align-items: unset;

      .leftLabel,
      .rightLabel {
        display: none;
      }

      .options {
        margin: 0px auto !important;

        .color {
          margin-top: 10px;
          .arrowIcon {
            left: 38% !important;

            svg {
              width: 10px;
              height: 10px;
            }
          }
        }
      }

      .colorOptions {
        width: 100%;
        margin: 15px auto 0px 20px;
      }
      .mobileLabels {
        display: flex;
        justify-content: space-between;
        margin: 10px 20px;
        font-size: 12px;
        font-family: "Montserrat-Medium";
      }
    }
  }

  @media screen and (max-width: 768px) {
    .item {
      .options {
        width: 300px !important;
        margin: 0px !important;
      }
    }
  }

  @media screen and (max-width: 400px) {
    .item {
      .options {
        width: 250px !important;
      }
    }
  }
}
