.passcodeContainer {
  display: flex;
  gap: 5px;
}

.passcodeInput {
  width: 48px;
  height: 75px;
  font-size: 26px;
  text-align: center;
  border-radius: 12px;
  border: 1px solid black;
  outline: none;
  padding: 0px;
}

@media screen and (max-width: 350px) {
  .passcodeInput {
    width: 40px;
  }
}
