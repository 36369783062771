.mainWrapper {
  padding: 50px 20%;

  .header {
    cursor: pointer;
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }

  .wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    padding-top: 20px;
    cursor: pointer;

    .language {
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      .languageOrder {
        width: 30px;
      }
      .languageOrder,
      .languageName {
        font-size: 18px;
        font-family: "Montserrat-Bold";
      }
    }
  }

  @media screen and (max-width: 1180px) {
    padding: 10px 15%;

    .language {
      width: 50% !important;
    }
  }
}
