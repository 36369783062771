.wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .header {
    width: calc(100% - 300px);
    margin: 20px;
    position: absolute;
    display: flex;

    .backButton {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }

    .tabs {
      min-width: 200px;
      display: flex;
      justify-content: space-around;
      position: absolute;
      left: 30%;

      .tab {
        min-width: 100px;
        border: 1px solid #000;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 14px;
      }
    }
  }

  .content {
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}
