.cardWrapper {
  max-width: 475px;
  height: 80dvh;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0px 0px 12.57034px 0px rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  .icon {
    margin: 100px 0px;
  }

  h1 {
    text-align: center;
    margin: 50px 30px;
  }

  h2 {
    padding-bottom: 10px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 22px;
    font-family: "Montserrat-Regular";
  }

  .coloredLabel {
    color: var(--color);
    text-align: center;
    text-shadow: 0.5px 0.5px 0px rgba(0, 0, 0, 0.25);
    font-family: "Montserrat-Medium";
    font-size: 18px;
    font-style: normal;
    line-height: 28px;
    margin: 50px 0px 100px 0px;

    max-width: 80%;
  }

  @media screen and (max-width: 1180px) {
    margin-top: 20px;
    width: 95%;
  }
}
