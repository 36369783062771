.manageWalletWrapper {
  padding: 20px;
  .title {
    padding-bottom: 10px;
    border-bottom: 1px solid #f2f2f2;
    color: var(--color);
    font-family: "Montserrat-Bold";
    font-size: 18px;
    line-height: 18px;
  }

  .labels {
    color: var(--color);

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;
      border-bottom: 1px solid #f2f2f2;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1180px) {
    margin-top: 20px;
  }
}
