.contentWrapper {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 50px;

  h1 {
    margin-bottom: 50px;
    font-family: "Montserrat-SemiBold";
    font-size: 24px;
  }

  .subtitle {
    color: #999aa2;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    margin-bottom: 30px;
  }

  .coloredLabel {
    color: #540a9c;
    font-family: "Montserrat-Medium";
    font-size: 16px;
    margin-bottom: 30px;
  }

  .passwordValidations {
    margin: 30px 0px;
  }

  .inputs {
    max-width: 450px;
    min-width: 350px;

    input {
      background-color: #fff;
      height: 48px;
      border: none;
      border-radius: 4px;
      width: 100%;
      padding: 5px 20px;
      font-size: 16px;
      outline: none;
      margin: 5px 0px;
      font-family: "Montserrat-Regular";
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;

      &::placeholder {
        color: #000;
      }
    }

    div {
      button {
        margin-bottom: 5px;
      }
    }
    .showPasscodeLabel {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-bottom: 10px;

      span {
        font-family: "Montserrat-Medium";
        font-size: 12px;
      }

      div {
        margin-left: 5px;

        svg {
          height: 12px;
        }
      }
    }

    .hint {
      color: #bababa;
      font-size: 14px;
      margin-top: 10px;
    }
  }

  .hints {
    max-width: 300px;
    text-align: center;
    margin: 50px auto 50px auto;
    display: flex;
    flex-direction: column;
    color: var(--color);
    font-family: "Montserrat-Regular";
    font-size: 16px;
    line-height: 26px;

    span {
      margin-bottom: 5px;
    }
  }

  .buttons {
    width: 440px;
    display: flex;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 48%;
      margin-right: 10px;
    }

    .skipBtn {
      width: 48%;
      height: 48px;
      border: 1px solid var(--color);
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 15px;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: var(--color);
        color: #fff;
      }
    }
  }

  @media screen and (max-width: 1180px) {
    width: 90%;
    margin-bottom: 100px;
    margin-top: 50px;

    .inputs {
      max-width: 320px;
    }
  }

  @media screen and (max-width: 900px) {
    .buttons {
      width: 320px;
    }
  }
}
