.journalSliderContainer {
  max-width: 100%;
  margin: 0px auto 50px auto;

  div div div div div {
    margin: 0px 5px !important;
  }
  .row {
    width: 100%;
    margin: 0 auto 80px 0px;
    background-color: #fff;
    border-radius: 20px;
    padding: 0px 5px;
    display: flex !important;
    flex-direction: column;
    align-items: center;
    border: 1px solid var(--color);
    position: relative;
    height: 900px;

    @media screen and (max-width: 1180px) {
      padding: 0px;
      width: 95%;
      min-height: 830px;
      margin: 0 auto;
    }

    .imagePart {
      width: 30%;
      height: 150px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 40px;
        height: 100%;
        object-fit: cover;
      }
    }

    .title {
      font-family: "Montserrat-SemiBold";
      font-size: 14px;
    }

    .firstCol {
      display: flex;
      align-items: center;

      .rightPart {
        height: 95%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
    }

    .secondCol {
      display: flex;
      justify-content: center;
      align-items: center;

      .noRatingsBox {
        width: 70%;
        height: 48px;
        border: 2px solid var(--color);
        border-radius: 4px;
        margin: 0px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Montserrat-Medium";
        font-size: 15px;
        color: var(--color);
        cursor: pointer;
        transition: 0.2s ease-in-out;
        text-align: center;

        &:hover {
          background-color: var(--color);
          color: #fff;
        }
      }

      .productFeedback {
        width: 100%;
        height: 80%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .headerLabel {
          font-family: "Montserrat-SemiBold";
          font-size: 14px;

          .tastingLabel {
            font-family: "Montserrat-Regular";
          }
        }

        svg {
          width: 30px;
        }

        .rateEmojis {
          width: 220px;
          display: flex;
          justify-content: space-between;

          .activeIcon {
            border: 2px solid #ff6333;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            margin-top: 6px;
          }

          .disabled {
            opacity: 0.5;
          }
        }
      }
    }

    .thirdCol {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        margin-left: 0px;
        margin-bottom: 0px;
      }
    }

    .firstCol,
    .secondCol,
    .thirdCol {
      width: 90%;
      height: 200px;
      margin: 10px 5px !important;
      position: relative;
      border: 1px solid #dde3eb;
      border-radius: 8px;
      box-shadow: 0px 4px 4px 0px rgba(220, 225, 234, 1);
      padding: 0px 10px;

      .expandIcon {
        position: absolute;
        right: 20px;
        bottom: 20px;
        cursor: pointer;
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
          font-size: 12px;
        }
      }
    }

    .lastColumn {
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 5px 5px 30px 5px;
      .bottomItems {
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-left: 0px;

        .mainBtn {
          margin-left: 0px !important;
        }
      }

      .discount {
        color: #ff6333;
        font-size: 12px;
        text-decoration: line-through;
        font-family: "Montserrat-SemiBold";
      }

      .price {
        width: 100%;
        margin: 10px 0px;
        font-family: "Montserrat-Regular";
        font-size: 20px;
      }

      .requestedToSupplyLabel {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 85%;
        margin-left: 20px;
        color: var(--color);
        font-size: 12px;
      }

      .mainBtn {
        width: 200px;
        height: 40px;
        background-color: var(--color);
        color: #fff;
        padding: 10px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .deleteWineIcon {
      border: 1px solid #000;
      border-radius: 4px;
      padding: 6px 10px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      bottom: 10px;
      left: 20px;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}
.noOrdersText {
  font-family: "Montserrat-SemiBold";
  font-size: 20px;
  margin-top: 50px;
  text-align: center;
}

.header {
  position: relative;

  .customDots {
    max-width: fit-content;
    margin: 0 auto;
    height: 20px;
    display: flex;
    justify-content: space-between;

    div {
      border-radius: 50%;
      margin: 0px 3px;
    }
  }

  .rightPart {
    position: absolute;
    right: 20px;
    top: -5px;
  }
}
