@import "/src/styles/colors";

.profilePageContent {
  display: flex;
  padding-bottom: 20px;

  .leftContainer {
    position: relative;
    min-width: 310px;
    margin: 0 auto;
    overflow: auto;
    background-color: $white;
    border-radius: 5px;
    padding: 2%;
    margin: 0px 5px;
    transition: width 0.3s linear;

    .upperContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;

      .name {
        width: 100%;
        max-width: 150px;
        word-wrap: break-word;
      }

      .picture {
        width: 100px;
        height: 100px;
        border: 1px solid rgba(250, 224, 214, 0.3);
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        object-fit: cover;
        margin-right: 20px;

        span {
          width: 100%;
          max-width: 150px;
          word-wrap: break-word;
          text-align: center;
          color: #fff;
        }
      }

      .btn {
        text-align: center;
        cursor: pointer;
        font-family: "Montserrat-Medium";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        word-wrap: break-word;
      }
    }

    .loginMethod,
    .personalInformation,
    .legalInformation,
    .settings {
      margin-top: 40px;

      h1 {
        font-size: 18px;
        font-family: "Montserrat-SemiBold";
      }

      .operation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #20202035;
        padding-bottom: 15px;
        padding-top: 15px;
        cursor: pointer;

        .label {
          font-family: "Montserrat-Regular";
          font-size: 16px;
        }

        .clickable {
          margin-right: 20px;
        }
      }
    }

    .logoutButton {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }
  }

  .middleContainer {
    margin-right: 5px;
  }
  .rightContainer,
  .middleContainer {
    width: 0;
    overflow: auto;
    background-color: $white;
    border-radius: 5px;
    transition: width 0.3s linear;
    position: relative;
  }

  .previousBtn {
    position: absolute;
    top: 10px;
    left: 10px;
    cursor: pointer;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;

  .siteTitle {
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #000;
    border-radius: 42px;
    padding: 5px 10px 5px 18px;
    cursor: pointer;

    svg {
      margin-left: 2px;
    }
  }
}

@media screen and (max-width: 1500px) {
  .loginMethod,
  .personalInformation,
  .legalInformation,
  .settings {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1180px) {
  .profilePageContent {
    flex-direction: column;
    padding: 0px 10px;

    .leftContainer {
      margin: 0;
      padding-bottom: 20px;
      height: calc(100dvh - 130px);

      .header {
        margin-top: 20px;
        padding: 0px 20px;
      }

      .upperContainer {
        width: 100%;
        margin-top: 20px;
        flex-direction: column;

        .profilePic {
          width: 120px;
          display: flex;
          justify-content: center;
          position: relative;
        }

        .cameraIcon {
          position: absolute;
          top: 0px;
          right: 0px;
        }

        .profileInfo {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 20px;
          font-family: "Montserrat-Regular";
          line-height: 22px;

          span {
            // width: 80%;
            word-wrap: break-word;
            text-align: center;
          }
        }
      }

      .loginMethod,
      .personalInformation,
      .legalInformation,
      .settings {
        max-width: 100%;
        padding: 0px 20px;
      }
    }
    .rightContainer,
    .middleContainer {
      display: none;
    }
  }
  .leftContainer,
  .rightContainer {
    width: 100% !important;
    margin-bottom: 20px;
    padding: 20px 0px !important;
  }
  .rightContainer {
    &::after {
      display: none;
    }
  }
}

@media screen and (max-width: 1180px) {
  .upperContainer .picture {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 768px) {
  .profilePageContent {
    height: 100%;
  }
}
